<template>
  <v-tooltip v-model="tooltip" transition="scroll-y-transition" :location="orientation">
    <template v-slot:activator="{ props }">
      <v-btn v-if="label" v-bind="props" :prepend-icon="icon" :color="edit ? '' : color" :disabled="edit"
             @click="onClick" :size="size" :density="density" variant="text">{{ label }}
      </v-btn>
      <v-btn v-else v-bind="props" :icon="icon !== undefined" :color="edit ? '' : color" :disabled="edit"
             @click="onClick" :size="size" :density="density" variant="text">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span v-html="tooltipText"></span>
  </v-tooltip>
</template>
<script setup>

import {ref} from "vue";

const props = defineProps({
  icon: String,
  color: String,
  tooltipText: String,
  edit: Boolean,
  dot: Boolean,
  label: String,
  density: {
    type: String,
    default: 'default'
  },
  size: {
    type: String,
    default: 'default'
  },
  orientation: {
    type: String,
    default: 'bottom'
  }
})

const tooltip = ref(false)

const emit = defineEmits(['clicked'])

function onClick() {
  tooltip.value = false;
  emit('clicked');
}
</script>
