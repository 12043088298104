<template>
  Loading ...
</template>
<script setup>
import {useAuth} from '@/composables/useAuth'
import {useRouter} from "vue-router";
import {onMounted} from "vue";

const auth = useAuth()
const router = useRouter()

onMounted(async () => {
  try {
    console.log('Receiving access token')
    await auth.$auth.signInCallback()
    await router.push('/')
  } catch (error) {
    console.log(error)
  }
})

</script>
