<template>
    <Loading :loading="loading" :text="$t('referential.dataset.loading-datasets')"/>
    <Components :components="datasets" :component-label="$tc('referential.dataset.label', 1)"
                :create-component-label="$t('referential.dataset.create')" :allow-creation="true"
                @create="createDataset = true" @navigate="navigateToDataset"/>
    <v-dialog v-model="createDataset" persistent>
        <CreateDataset @canceled="createDataset = false" @saved="doCreateDataset" @imported="doCreateDataset" />
    </v-dialog>
</template>
<script setup>
import { toast } from 'vue3-toastify';
import CreateDataset from "@/components/referential/CreateDataset.vue";
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";
import {onMounted, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

let datasets = ref([])
let createDataset = ref(false)
let loading = ref(true)

let referentialApi = useReferentialApi()

onMounted(() => referentialApi.$dataset.list()
  .then(result => datasets.value = result)
  .finally(() => loading.value = false)
)

let {t} = useI18n()

function doCreateDataset(datasetToCreate) {
  createDataset.value = false;
  referentialApi.$dataset.create(datasetToCreate)
    .then(created => {
      toast.success(t('referential.dataset.created', {code: created.code}))
      navigateToDataset(created.code);
    })
}

let router = useRouter()

function navigateToDataset(datasetCode) {
  router.push({name: 'dataset', params: {datasetCode: datasetCode}})
}
</script>