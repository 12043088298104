import {useAuthStore} from "@/store/auth";
import CollectionApi from "@/services/api/runner/CollectionApi";

export const useRunnerApi = () => {
    const authStore = useAuthStore()
    let accessToken = authStore.accessToken;

    return {
        $collection: new CollectionApi(accessToken)
    }
}