import BaseApi from "@/services/api/BaseApi";

export default class CollectionApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    list(collectionCode, last = false) {
        let path = "/runner-api/v1/collections";
        let params = new URLSearchParams();
        if (collectionCode) {
            params.set("collectionCode", collectionCode)
        }
        if (last) {
            params.set("last", last + '');
        }
        if (params.size > 0) {
            path += "?" + params.toString();
        }
        return this.call(path, {
            responseFormat: 'json',
            action: {
                key: 'runner.collection.actions.list'
            }
        })
    }

    changeState(collectionCode, collectionVersion, newState) {
        let action = {
            collections: [{
                code: collectionCode,
                version: collectionVersion
            }]
        };
        if (newState === 'ONLINE') {
            action.type = 'LOAD'
        } else if (newState === 'OFFLINE') {
            action.type = 'UNLOAD'
        }

        if (action.type) {
            return this.call('/runner-api/v1/collections/manage', {
                responseFormat: 'ignore',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([action]),
                action: {
                    key: 'runner.collection.actions.manage'
                }
            })
        }
    }

}