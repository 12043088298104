import i18n from "@/plugins/i18n";
import {toast} from "vue3-toastify";

const CATALOG_URL = import.meta.env.VITE_RULESHAKE_CATALOG_URL;
const REFERENTIAL_URL = import.meta.env.VITE_RULESHAKE_REFERENTIAL_URL;
const RUNNER_URL = import.meta.env.VITE_RULESHAKE_RUNNER_URL;

export default class BaseApi {

    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    call(path, options) {
        const headerAuth = {
            'Authorization': 'Bearer ' + this.accessToken
        }
        const headers = {...headerAuth, ...options?.headers}
        const request = {
            method: options?.method ? options.method : 'GET',
            headers: headers,
            body: options?.body ? options.body : null,
        }
        const resolvedPath = path
            .replace(/^\/catalog-api/, CATALOG_URL)
            .replace(/^\/referential-api/, REFERENTIAL_URL)
            .replace(/^\/runner-api/, RUNNER_URL);
        return fetch(resolvedPath, request)
            .then(response => {
                if (response.status >= 400) {
                    return this.handleError(response, options.action)
                }
                if (options.responseFormat === 'json') {
                    return response.json()
                }
                if (options.responseFormat === 'blob') {
                    return response.blob()
                }
                if (options.responseFormat === 'text') {
                    return response.text()
                }
                return true
            });
    }

    securedFetch(path, init) {
        const headerAuth = {
            'Authorization': 'Bearer ' + this.accessToken
        }
        const headers = {...headerAuth, ...init?.headers}
        const request = init || {}
        request.headers = headers
        const resolvedPath = path
            .replace(/^\/catalog-api/, CATALOG_URL)
            .replace(/^\/referential-api/, REFERENTIAL_URL)
            .replace(/^\/runner-api/, RUNNER_URL);
        return fetch(resolvedPath, request);
    }

    handleJson(response, action) {
        if (response.status >= 400) {
            return this.handleError(response, action)
        }
        return response.json();
    }

    handleVoid(response, action) {
        if (response.status >= 400) {
            return this.handleError(response, action)
        }
        return true;
    }

    handleError(response, action) {
        const {t} = i18n.global
        if (response.status >= 400 && response.status < 500) {
            return response.json()
                .then(json => {
                    let errorMsgKey = 'common.an-error-occurred'
                    if (response.status === 401 || response.status === 403) {
                        errorMsgKey = 'common.you-r-not-allowed'
                    }
                    const errorMsg = `${t(errorMsgKey)} ${t(action.key, action.params)}`
                    console.log(errorMsg)
                    toast.error(errorMsg)
                    throw json
                })
        }
        return response.text()
            .then(text => {
                let errorMsgKey = 'common.an-error-occurred'
                if (response.status === 401 || response.status === 403) {
                    errorMsgKey = 'common.you-r-not-allowed'
                }
                const errorMsg = `${t(errorMsgKey)} ${t(action.key, action.params)}`
                console.log(errorMsg)
                toast.error(errorMsg)
                throw errorMsg
            })
    }

}