<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="records">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{
            $tc('referential.classifier.label', 2)
          }} ({{ classifiers && classifiers.length > 0 ? classifiers.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <Components :components="classifiers" :enable-tags="true"
                    :component-label="$tc('referential.classifier.label', 1)"
                    :create-component-label="$t('referential.classifier.create')" :allow-creation="true"
                    @create="createClassifier = true" @navigate="navigateToClassifier"/>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="createClassifier" persistent>
    <CreateClassifier :dataset="dataset" @canceled="createClassifier = false"
                      @saved="doCreateClassifier"></CreateClassifier>
  </v-dialog>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import CreateClassifier from "@/components/referential/CreateClassifier.vue";
import Components from "@/components/common/Components.vue";
import {onMounted, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

const props = defineProps(['dataset'])

let panel = ref([])
let classifiers = ref([])
let createClassifier = ref(false)

let referentialApi = useReferentialApi()

onMounted(() => {
  referentialApi.$classifier.listByDataset(props.dataset.code)
    .then(result => {
      classifiers.value = result
      classifiers.value.forEach(classifier => classifier.tags = [classifier.datasetCode])
    })
})

let {t} = useI18n()

function doCreateClassifier(classifierToCreate) {
  createClassifier.value = false;
  referentialApi.$classifier.create(classifierToCreate)
    .then(created => {
      toast.success(t('referential.classifier.created', {code: created.code}))
      navigateToClassifier(created.code);
    })
}

let router = useRouter()

function navigateToClassifier(classifierCode) {
  router.push({name: 'classifier', params: {classifierCode: classifierCode}})
}
</script>