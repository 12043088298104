console.log("Starting application ...")

import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from "./plugins/i18n";
import {loadFonts} from './plugins/webfontloader'
import router from "./router"
import JsonViewer from 'vue-json-viewer'
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {createPinia} from "pinia";
import * as ConfirmDialog from 'vuejs-confirm-dialog'

loadFonts().then(r => console.log("Fonts loaded"))

console.log("Catalog API :", import.meta.env.VITE_RULESHAKE_CATALOG_URL)
console.log("Referential API :", import.meta.env.VITE_RULESHAKE_REFERENTIAL_URL)
console.log("Runner API :", import.meta.env.VITE_RULESHAKE_RUNNER_URL)

createApp(App)
    .use(createPinia())
    .use(vuetify)
    .use(i18n)
    .use(Vue3Toasity, {
        autoClose: 5000,
        clearOnUrlChange: false,
        style: {
            opacity: '1',
            userSelect: 'initial',
        }
    })
    .use(JsonViewer)
    .use(router)
    .use(ConfirmDialog)
    .mount('#app');
