<template>
  <div class="d-flex flex-column align-center mt-12">
    <v-row>
      <img src='/logo-green-atom.png' alt="studio" width="128">
    </v-row>
    <v-row class="mt-12 mb-6">
      <span class="text-h6">{{ $t('login.welcome-msg') }}</span>
    </v-row>
    <v-row>
      <v-btn @click="doLogin" color="primary">
        {{ $t('login.login') }}
      </v-btn>
    </v-row>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {useAuthStore} from "@/store/auth";
import {useAuth} from "@/composables/useAuth";

let auth = useAuth();
let authStore = useAuthStore();
let isAuthenticated = authStore.isLoggedIn;
let route = useRoute();
let router = useRouter();
let orga = ref(undefined)
const {t} = useI18n()

onMounted(async () => {
  if (!!authStore.isLoggedIn) {
    await router.push("/")
  }
})

function doLogin() {
  auth.$auth.signInRedirect();
}

</script>