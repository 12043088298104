<template>
  <v-row align="center">
    <v-col :cols="small ? 12 : 3" :class="small ? 'pb-0' :  ''">
      <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">
        {{ label }}s
      </span>
    </v-col>
    <v-col :cols="small ? 12 : $slots.append ? 7 : 9" :class="small ? 'pt-0' : ''">
      <v-radio-group v-model="loadOption" :inline="!small" :readonly="!edit" hide-details color="primary" >
        <v-radio :label="t('common.empty')" value="empty" />
        <v-radio :label="t('catalog.variable.select-values')" value="selected" />
        <v-radio v-if="type === 'RECORD' || (items && items.length > 0)" :label="type === 'RECORD' ? t('catalog.variable.load-all-values-from-dataset') : t('catalog.variable.load-all-values')" value="all" />
      </v-radio-group>
      <div v-for="i in Array.from(values.keys())" class="mb-1 d-flex flex-row align-end">
        <Value v-model="values[i]" :edit="edit" :type="type" :label="label + ' ' + (i + 1)" :items="items" :small="small"/>
        <v-btn v-if="edit" icon="mdi-delete" variant="plain" color="error" @click="removeValue(i)" />
      </div>
      <v-row v-if="loadOption === 'selected'" justify="end" class="mt-1">
        <v-btn v-if="edit" @click="addValue" variant="text" color="primary">{{ t('catalog.variable.add-value') }}</v-btn>
      </v-row>
    </v-col>
    <v-col v-if="$slots.append" cols="2">
      <slot name="append"></slot>
    </v-col>
  </v-row>
</template>
<script setup>
import Value from "@/components/catalog/Value.vue";
import {useI18n} from "vue-i18n";
import {onMounted, ref, watch} from "vue";

const props = defineProps({
  modelValue: null,
  label: String,
  type: String,
  small: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    defaultValue: []
  },
  edit: Boolean
})

const values = ref([])
const loadOption = ref('empty')

onMounted(() => {
  if (props.modelValue) {
    if (props.modelValue.data) {
      values.value = props.modelValue.data
    }
    if (values.value && values.value.length > 0) {
      loadOption.value = 'selected'
    }
    if (props.modelValue.all) {
      loadOption.value = 'all'
    }
  }
})

watch(loadOption, (newVal) => {
  if (newVal === 'empty' || newVal === 'all') {
    values.value = []
  }
})

watch(values, (newVal) => {
  doEmit(newVal)
})

const {t} = useI18n()

const addValue = () => {
  values.value[values.value.length] = null
  doEmit(values.value)
}

const removeValue = (i) => {
  values.value.splice(i, 1)
}

const emit = defineEmits(["update:modelValue"])
const doEmit = (items) => {
  emit('update:modelValue', {
    all: loadOption.value === 'all',
    data: loadOption.value === 'selected' ? items : []
  })
}

</script>