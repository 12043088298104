<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="records" eager>
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.records.label') }} ({{ totalElements }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text :key="classifierRecordSearchKey">
        <v-row class="mt-4 mr-4" align="center">
          <v-spacer/>
          <v-menu anchor="bottom end" rounded>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props">
                {{ $t('common.actions') }}
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list density="compact" class="mt-2">
              <v-list-item prepend-icon="mdi-plus-thick" @click="doOpenRecord"
                           :title="$t('referential.records.add')"/>
              <v-list-item prepend-icon="mdi-publish" @click="openImportRecords = true"
                           :title="$t('referential.records.import-from-csv')"/>
              <v-list-item prepend-icon="mdi-download" @click="exportClassifierRecords"
                           :title="$t('referential.records.download-as-csv')"/>
            </v-list>
          </v-menu>
        </v-row>
        <v-card-title class="mt-8 border border-opacity-50 border rounded">
          <v-label class="pl-2">{{ $t('common.filter') }}</v-label>
          <v-row align="center" class="pa-2">
            <v-col v-for="property in properties" :cols="propertyColSize">
              <v-select v-model="filter[property.code]" :label="property.label"
                        :items="values[property.code]"
                        variant="outlined" hide-details bg-color="white" clearable/>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="py-6">
          <v-row class="border my-1 pa-2 bg-primary" dense>
            <v-col @click="doSort('code')" class="d-flex flex-row" style="cursor: pointer">
                            <span class="text-subtitle-1 font-weight-bold"
                                  style="user-select: none">{{ $t('common.code') }}</span>
              <div v-if="pagination.sortField === 'code'">
                <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </div>
            </v-col>
            <v-col v-for="property in properties" @click="doSort(property.code)" class="d-flex flex-row"
                   style="cursor: pointer">
                            <span class="text-subtitle-1 font-weight-bold" style="user-select: none">
                                {{ property.label }}
                            </span>
              <div v-if="pagination.sortField === property.code">
                <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </div>
            </v-col>
            <v-col>
                            <span class="text-subtitle-1 font-weight-bold" style="user-select: none">
                                {{ $t('referential.classifier.records.associated-dataset-keys') }}
                            </span>
            </v-col>
          </v-row>
          <div v-for="element in records">
            <v-row align="center" style="cursor: pointer"
                   :class="isSelected(element) ? 'border pa-2 bg-green-lighten-4' : 'border pa-2 bg-grey-lighten-5'"
                   dense
                   @click="select(element)">
              <v-col :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
                <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
                  {{ element.code }}
                </strong>
              </v-col>
              <v-col v-for="property in properties"
                     :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
                                <span v-if="classifier.type === 'COMPOSITE'"
                                      class="text-subtitle-1 font-weight-regular font-italic">
                                  {{ element.properties[property.code].value }}
                                </span>
                <span v-else class="text-subtitle-1 font-weight-regular font-italic">
                                    {{ element.value.value }}
                                </span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-regular font-italic"
                      v-html="associatedDatasetRecordKeys(element)"/>
              </v-col>
            </v-row>
          </div>
          <v-row align="center" class="mt-4 ml-1">
                        <span class="text-subtitle-1"
                              v-html="$t('referential.records.page', {nb: numberOfElements, total: totalElements})"/>
            <v-spacer/>
            <v-pagination v-model="pagination.page" active-color="primary" variant="text" total-visible="5"
                          :length="totalPages"/>
          </v-row>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="openRecord" persistent>
    <ClassifierRecord :classifier="classifier" :record="selectedRecord" @canceled="clearRecordDialog"
                      @saved="saveRecord" @deleted="deleteRecord"/>
  </v-dialog>
  <v-dialog v-model="openImportRecords" persistent>
    <ImportRecords @canceled="openImportRecords = false" @imported="importClassifierRecords"/>
  </v-dialog>
  <Loading :loading="loading" :text="loadingText"/>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import ClassifierRecord from "@/components/referential/ClassifierRecord.vue";
import ImportRecords from "@/components/referential/ImportRecords.vue";
import Loading from "@/components/common/Loading.vue";
import {computed, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useReferentialApi} from "@/composables/useReferentialApi";

const props = defineProps(['classifier'])

let classifierRecordSearchKey = ref(0)
let openRecord = ref(false)
let openImportRecords = ref(false)
let panel = ref([])
let records = ref([])
let selectedRecord = ref({})
let values = ref({})
let filter = reactive({})
let pagination = reactive({
  page: 1,
  sortField: null,
  sortDirection: 'ASC',
  size: 10
})
let totalPages = ref(null)
let totalElements = ref('?')
let numberOfElements = ref(null)
let loading = ref(false)
let loadingText = ref(null)

let {t} = useI18n()

let properties = computed(() => {
  if (props.classifier && props.classifier.type === 'COMPOSITE' && props.classifier.properties) {
    return props.classifier.properties
  }
  return [{
    code: 'value',
    label: t('common.value')
  }]
})

let propertyColSize = computed(() => Math.max(3, 12 / properties.value.length))

watch(filter, (val) => {
  pagination.page = 1
  search()
})

watch(pagination, () => search())

let referentialApi = useReferentialApi()

clearRecordDialog()
search()

function select(record) {
  selectedRecord.value = record
  openRecord.value = true
}

function clearRecordDialog() {
  openRecord.value = false;
  selectedRecord.value = {
    creation: true,
    properties: {},
  };
  if (props.classifier.type === 'COMPOSITE' && props.classifier.properties) {
    props.classifier.properties.forEach(property => selectedRecord.value.properties[property.code] = {value: null})
  } else {
    selectedRecord.value.value = {value: null}
  }
}

function saveRecord(record) {
  let promise;
  record.classifierCode = props.classifier.code
  record.type = props.classifier.type === 'COMPOSITE' ? 'COMPOSITE' : 'SIMPLE'
  if (record.creation) {
    promise = referentialApi.$classifier.addRecord(props.classifier.code, record)
  } else {
    promise = referentialApi.$classifier.updateRecord(props.classifier.code, record)
  }
  promise
    .then(saved => {
      toast.success(t('referential.records.' + (record.creation ? 'created' : 'updated'), {code: record.code}))
      search()
    })
    .finally(() => clearRecordDialog());
}

function deleteRecord(record) {
  referentialApi.$classifier.deleteRecord(props.classifier.code, record.code)
    .then(_ => {
      toast.warning(t('referential.records.deleted', {code: record.code}))
      search()
    })
    .finally(() => clearRecordDialog());
}

function isSelected(record) {
  if (record && selectedRecord.value) {
    return record.code === selectedRecord.value.code
  }
  return false
}

function doSort(property) {
  const currentSortField = pagination.sortField
  const currentSortDirection = pagination.sortDirection
  if (currentSortField === property) {
    if (currentSortDirection === 'ASC') {
      pagination.sortDirection = 'DESC'
    } else {
      pagination.sortDirection = 'ASC'
    }
  } else {
    pagination.sortDirection = 'ASC'
  }
  pagination.sortField = property
}

function search() {
  const newFilter = {}
  Object.assign(newFilter, filter, pagination)
  referentialApi.$classifier.searchRecords(props.classifier.code, newFilter)
    .then(result => {
      records.value = result.content
      totalPages.value = result.totalPages
      totalElements.value = result.totalElements
      numberOfElements.value = result.numberOfElements
      referentialApi.$classifier.propertiesValues(props.classifier.code, filter)
        .then(values => {
          values.value = values
        })
    })
}

function associatedDatasetRecordKeys(record) {
  if (record && record.datasetRecordsKeys) {
    const tmp = record.datasetRecordsKeys.reduce((total, key) => {
      total[key.propertyCode] = (total[key.propertyCode] || 0) + 1
      return total
    }, {})
    return Object.keys(tmp).map(k => '<strong>' + tmp[k] + '</strong> ' + k).join(', ')
  }
  return 'None'
}

function exportClassifierRecords() {
  loading.value = true
  loadingText.value = t('referential.records.loading-export', {code: props.classifier.code})
  referentialApi.$classifier.exportRecords(props.classifier.code)
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `classifier-${props.classifier.code}-records.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .finally(() => clearLoading())
}

function importClassifierRecords(importData) {
  openImportRecords.value = false
  loading.value = true
  loadingText.value = t('referential.records.loading-import', {code: props.classifier.code})
  referentialApi.$classifier.importRecords(props.classifier.code, importData.file, importData.mode)
    .then(response => {
      toast.success(t('referential.records.import.success-msg'))
      search()
    })
    .finally(() => clearLoading())
}

function clearLoading() {
  loading.value = false;
  loadingText.value = null;
}

function doOpenRecord() {
  clearRecordDialog()
  openRecord.value = true
}
</script>