<template>
  <ModuleHeader code="catalog"/>
  <v-tabs v-model="tab" color="primary">
    <v-tab value="collections">
      {{ $tc('catalog.collection.label', 2) }}
    </v-tab>
    <!--    <v-tab value="comparisons">-->
    <!--      {{ $tc('catalog.comparison.label', 2) }}-->
    <!--    </v-tab>-->
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item value="collections">
      <v-card class="mx-auto">
        <Collections/>
      </v-card>
    </v-window-item>
    <v-window-item value="comparisons">
      <v-card class="mx-auto">
        <h1>Comparisons</h1>
      </v-card>
    </v-window-item>
  </v-window>
</template>

<script setup>

import ModuleHeader from "@/components/common/ModuleHeader.vue";
import Collections from "@/components/catalog/Collections.vue";
import {ref} from "vue";

let tab = ref('collections')
</script>