<template>
  <v-card width="896px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="$t('common.export.title', {label: code, type: type})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <json-viewer :value="json" copyable boxed expanded></json-viewer>
    </v-card-text>
    <v-card-actions>
      <v-row class="mr-3">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closed')" color="error">{{ $t('common.cancel') }}</v-btn>
        <v-btn @click="download()" color="primary">{{ $t('common.download') }}</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script setup>

import {ref} from "vue";

const props = defineProps(["code", "type", "data"])

const json = ref({})
json.value = props.data
delete json.value.createdDate
delete json.value.lastModifiedDate
delete json.value.createdByUser
delete json.value.modifiedByUser

const download = () => {
  const url = window.URL.createObjectURL(new Blob([JSON.stringify(props.data, null, 2)]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${props.type}-${props.code}.json`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

</script>