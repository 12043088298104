<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="subVariables">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('catalog.variable.sub-variables.title') }}
          ({{ variables && variables.length > 0 ? variables.length : 0 }})</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="my-2">
              <v-spacer/>
              <v-btn v-if="drag" color="warning" class="mr-2" @click="saveNewOrder">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                <span>{{ $t('catalog.variable.save-new-order') }}</span>
              </v-btn>
              <v-btn color="primary" @click="createVariable=true">
                <v-icon class="mr-2">mdi-plus-thick</v-icon>
                <span>{{ $t('catalog.variable.add') }}</span>
              </v-btn>
            </v-row>
          </v-card-title>
          <div v-if="variables.length > 0">
            <draggable v-model="variables" handle=".handle" @change="drag = true" item-key="code">
              <template #header>
                <v-row class="border mt-1 pa-2 bg-primary" dense>
                  <v-col cols="4">
                    <span class="text-subtitle-1 font-weight-bold ml-6">{{ $t('common.code') }}</span>
                  </v-col>
                  <v-col cols="5">
                    <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
                  </v-col>
                  <v-col>
                    <span class="text-subtitle-1 font-weight-bold">{{ $t('catalog.variable.nature.label') }}</span>
                  </v-col>
                  <v-col>
                    <span class="text-subtitle-1 font-weight-bold">{{ $t('catalog.variable.type.label') }}</span>
                  </v-col>
                </v-row>
              </template>
              <template #item="{element}">
                <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" @click="navigateToVariable(element)" dense>
                  <v-col cols="4">
                    <v-icon class="handle mr-2" size="small">mdi-drag-horizontal-variant</v-icon>
                    <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.reference.code }}</strong>
                  </v-col>
                  <v-col cols="5">
                    <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                  </v-col>
                  <v-col>
                    <v-chip color="purple" label><span style="font-family: monospace, serif">{{ $t('catalog.variable.nature.' + element.nature.toLowerCase()) }}</span></v-chip>
                  </v-col>
                  <v-col>
                    <v-chip color="blue" label><span style="font-family: monospace, serif">{{ $t('catalog.variable.type.' + element.type.toLowerCase()) }}</span></v-chip>
                  </v-col>
                </v-row>
              </template>
            </draggable>
          </div>
          <v-alert v-else type="info" prominent variant="outlined">
            <span v-if="isCollection" class="text-subtitle-1 mb-6 mt-3" v-html="$t('catalog.collection.has-no-sub-variables-alert', {label: label})"/>
            <span v-else class="text-subtitle-1 mb-6 mt-3" v-html="$t('catalog.variable.sub-variables.has-no-sub-variables-alert', {label: label})"/>
          </v-alert>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="createVariable" persistent>
    <CreateVariable :parent="reference" @canceled="createVariable = false" @saved="doCreateVariable"/>
  </v-dialog>
</template>
<script setup>
import { toast } from 'vue3-toastify';
import CreateVariable from "@/components/catalog/CreateVariable.vue";
import draggable from "vuedraggable";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useI18n} from "vue-i18n";

const props = defineProps({
  reference: String,
  label: String,
  modelValue: Array,
  isCollection: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

let variables = computed({
  get() { return props.modelValue },
  set(newValue) { emit('update:modelValue', newValue) }
})

let createVariable = ref(false)
let panel = ref(['subVariables'])
let drag = ref(false)

const router = useRouter()
const catalogApi = useCatalogApi()
let {t} = useI18n()

function navigateToVariable(variable) {
  router.push({name: 'variable', params: {variableReference: variable.reference.parent + '/' + variable.reference.code}})
}

function doCreateVariable(variableToCreate) {
  let variablePromise;
  if (props.isCollection) {
    variablePromise = catalogApi.$variable.addVariableToCollection(props.reference, variableToCreate);
  } else {
    variablePromise = catalogApi.$variable.addVariableToComposite(props.reference, variableToCreate);
  }
  let variableToCreateReference = variableToCreate.reference.parent + '/' + variableToCreate.reference.code
  variablePromise
    .then(createdVariable => {
      toast.success(t('catalog.variable.sub-variables.created', {reference: variableToCreateReference}));
      variables.value.push(createdVariable);
      createVariable.value = false
    })
}

function saveNewOrder() {
  drag.value = false;
  const newOrder = new Map();
  for (const [index, value] of variables.value.entries()) {
    newOrder.set(value.reference.parent + '/' + value.reference.code, index);
  }
  catalogApi.$variable.saveNewSubVariablesOrder(props.reference, newOrder)
    .then(result => toast.success(t('catalog.variable.sub-variables.new-order-saved')))
}
</script>