import BaseApi from "@/services/api/BaseApi";

export default class SimulationApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    simulateCollection(collectionCode, variables) {
        return this.call(`/catalog-api/v1/simulations/${collectionCode}`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variables),
            action: {
                key: 'catalog.simulation.actions.simulate',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    listCases(collectionCode) {
        return this.call(`/catalog-api/v1/simulations/${collectionCode}/cases`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.simulation.actions.list-cases',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    getCase(collectionCode, caseCode) {
        return this.call(`/catalog-api/v1/simulations/${collectionCode}/cases/${caseCode}`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.simulation.actions.load-case',
                params: {
                    code: caseCode
                }
            }
        })
    }

    saveCase(caseToSave) {
        return this.call(`/catalog-api/v1/simulations/${caseToSave.collectionCode}/cases`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(caseToSave),
            action: {
                key: 'catalog.simulation.actions.save-case',
                params: {
                    code: caseToSave.code
                }
            }
        })
    }

    deleteCase(caseToDelete) {
        return this.call(`/catalog-api/v1/simulations/${caseToDelete.collectionCode}/cases/${caseToDelete.code}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.simulation.actions.delete-case',
                params: {
                    code: caseToDelete.code
                }
            }
        })
    }
}