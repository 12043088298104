import BaseApi from "@/services/api/BaseApi";

export default class CollectionApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    list() {
        return this.call("/catalog-api/v1/collections", {
            responseFormat: 'json',
            action: {
                key: 'catalog.collection.actions.list'
            }
        })
    }

    find(collectionCode) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.collection.actions.find',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    create(collection) {
        return this.call("/catalog-api/v1/collections", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(collection),
            action: {
                key: 'catalog.collection.actions.create',
                params: {
                    code: collection.code
                }
            }
        })
    }

    update(collection) {
        return this.call(`/catalog-api/v1/collections/${collection.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(collection),
            action: {
                key: 'catalog.collection.actions.update',
                params: {
                    code: collection.code
                }
            }
        })
    }

    duplicate(collectionCode, duplicateInfos) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/duplicate`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(duplicateInfos),
            action: {
                key: 'catalog.collection.actions.duplicate',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    delete(collectionCode) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.collection.actions.delete',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    exportCollection(collectionCode) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/export`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.collection.actions.export',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    importCollection(collectionImport) {
        return this.call("/catalog-api/v1/collections/import", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(collectionImport),
            action: {
                key: 'catalog.collection.actions.import'
            }
        })
    }

    simulateCollection(collectionCode, variables) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/simulations`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variables),
            action: {
                key: 'catalog.collection.actions.simulate',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    listPublications(collectionCode) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/publications`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.publication.actions.list',
                params: {
                    code: collectionCode,
                    type: 'collection'
                }
            }
        })
    }

    publish(collectionCode, action) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/publications`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(action),
            action: {
                key: 'catalog.publication.actions.publish',
                params: {
                    code: collectionCode,
                    type: 'collection'
                }
            }
        })
    }

    deploy(collectionCode, version) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/publications/${version}/deploy`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            action: {
                key: 'catalog.publication.actions.deploy',
                params: {
                    code: collectionCode,
                    type: 'collection'
                }
            }
        })
    }

}