import {useAuthStore} from "@/store/auth";
import CollectionApi from "@/services/api/catalog/CollectionApi";
import VariableApi from "@/services/api/catalog/VariableApi";
import DatasetApi from "@/services/api/referential/DatasetApi";
import ClassifierApi from "@/services/api/referential/ClassifierApi";

export const useReferentialApi = () => {
    const authStore = useAuthStore()
    let accessToken = authStore.accessToken;
    return {
        $dataset: new DatasetApi(accessToken),
        $classifier: new ClassifierApi(accessToken)
    }
}