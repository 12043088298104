/**
 * Compute variable reference
 * @param {Variable} variable
 * @param {number} instance
 * @returns {string}
 */
export function computeVariableReference(variable, instance = undefined) {
    if (instance) {
        return variable.reference.parent + "/" + variable.reference.code + "[" + instance + "]"
    }
    return variable.reference.parent + "/" + variable.reference.code
}

/**
 * Extract collection code from variable
 * @param {string} reference
 * @returns {string}
 */
export function getCollectionCode(reference) {
    return reference.substring(0, reference.indexOf("/"))
}

/**
 * get reference parents
 * @param {string} reference
 * @returns {Array<string>}
 */
export function getParents(reference) {
    let elements = reference.split('/')
    elements.shift()
    elements.pop()
    return elements
}

/**
 * get parent reference
 * @param {string} reference
 * @returns {null|string}
 */
export function getParent(reference) {
    return reference.indexOf("/") === -1 ? null : reference.substring(0, reference.lastIndexOf("/"))
}

export function getCode(reference) {}