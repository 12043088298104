<template>
    <div v-if="dataset" class="align-center">
        <Loading :loading="loading" :text="$t('referential.dataset.loading-dataset', {code: dataset.code})"/>
        <v-card-actions>
            <ReferentialBreadcrumb :dataset="dataset.code"/>
            <v-spacer/>
            <ButtonWithTooltip icon="mdi-download" color="action" :edit="edit" @clicked="openJson = true"
                               variant="contained-text" size="large" density="comfortable"
                               :tooltip-text="$t('referential.dataset.export.label')"
                               :label="$t('common.export.label')"/>
        </v-card-actions>
        <v-card class="mt-3" density="compact">
            <v-card-actions>
                <v-spacer/>
                <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                                   :tooltip-text="$t('referential.dataset.edit')"/>
                <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteDataset"
                                   :tooltip-text="$t('referential.dataset.delete')"/>
            </v-card-actions>

            <v-card-text>
                <v-row align="center" justify="center">
                    <v-col cols="4">
                        <CodeTextField v-model="dataset.code" :edit="false"/>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field v-model="dataset.label" :label="$t('common.label')" :readonly="!edit"
                                      :variant="edit ? 'outlined' : 'plain'" hide-details
                                      :bg-color="edit ? 'white' : ''"/>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn v-if="edit" color="error" @click="cancelEditing">{{ $t('common.cancel') }}</v-btn>
                <v-btn v-if="edit" color="primary" @click="updateDataset">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
        </v-card>
        <DatasetProperties :dataset="dataset"/>
        <DatasetRecords :dataset="dataset"/>
        <DatasetClassifiers :dataset="dataset"/>
        <v-dialog v-model="openJson" persistent>
            <DisplayJson :code="dataset.code" type="dataset" :data="dataset" @closed="openJson = false"/>
        </v-dialog>
    </div>
</template>
<script setup>
import { toast } from 'vue3-toastify';
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import DatasetProperties from "@/components/referential/DatasetProperties.vue";
import DatasetRecords from "@/components/referential/DatasetRecords.vue";
import ReferentialBreadcrumb from "@/components/referential/ReferentialBreadcrumb.vue";
import DatasetClassifiers from "@/components/referential/DatasetClassifiers.vue";
import Loading from "@/components/common/Loading.vue";
import DisplayJson from "@/components/common/DisplayJson.vue";
import {onMounted, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import useConfirmDialog from "@/composables/useConfirmDialog";

let dataset = ref(null)
let edit = ref(false)
let loading = ref(true)
let openJson = ref(false)

let referentialApi = useReferentialApi()
let route = useRoute()
let router = useRouter()

onMounted(() => referentialApi.$dataset.find(route.params.datasetCode)
  .then(result => dataset.value = result)
  .finally(() => loading.value = false)
)

let {t} = useI18n()

function deleteDataset() {
  useConfirmDialog(
    {
      title: t('referential.dataset.delete-msg', {code: dataset.value.code}),
      message: t('referential.dataset.confirm-delete-msg', {code: dataset.value.code}),
      confirmation: dataset.value.code
    },
    () => referentialApi.$dataset.delete(dataset.value.code)
      .then(deleteOK => {
        if (deleteOK) {
          toast.warning(t('referential.dataset.deleted', {code: dataset.value.code}))
          router.push({name: 'referential'});
        } else {
          toast.error(t('referential.dataset.error-on-delete', {code: dataset.value.code}))
        }
      })
  )
}
function cancelEditing() {
  edit.value = !edit.value;
}

function updateDataset() {
  referentialApi.$dataset.update(dataset.value)
    .then(() => toast.success(t('referential.dataset.updated', {code: dataset.value.code})))
    .finally(() => edit.value = false)
}
</script>