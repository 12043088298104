<template>
  <v-card color="white" elevation="0" border class="mb-6 pa-2 border-opacity-50">
    <v-row align="center">
      <v-col cols="5">
        <span class="text-subtitle-1 v-label ml-1">{{ label }}</span>
      </v-col>
      <v-col class="d-flex flex-row mr-2">
        <v-col class="pa-1">
          <v-btn :variant="yesValue ? 'flat': 'outlined'" color="primary" :active="yesValue" block @click="yes">{{ $t('common.yes') }}</v-btn>
        </v-col>
        <v-col class="pa-1">
          <v-btn :variant="noValue ? 'flat': 'outlined'" color="primary" :active="noValue" block @click="no" class="ml-2">{{ $t('common.no') }}</v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<script setup>

import {ref} from "vue";

const props = defineProps({
  label: null,
  modelValue: false,
  edit: false
})

let yesValue = ref(null)
let noValue = ref(null)

if (props.modelValue) {
  yesValue.value = true
} else {
  noValue.value = true
}

const emit = defineEmits(['update:modelValue'])

function yes() {
  yesValue.value = true
  noValue.value = false
  emit("update:modelValue", true);
}

function no() {
  yesValue.value = false
  noValue.value = true
  emit("update:modelValue", false);
}
</script>