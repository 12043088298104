<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-chip v-bind="props" size="large" :prepend-icon="current.icon" :color="current.color"
                    append-icon="mdi-chevron-down">
                <span>{{ $t('runner.collection.status.' + modelValue.toLowerCase()) }}</span>
            </v-chip>
        </template>

        <v-list>
            <v-list-item v-for="state in Object.keys(states).filter(s => s !== modelValue)" @click="changeState(state)" :base-color="states[state].color">
                <v-icon class="mr-3" :color="states[state].color">{{ states[state].icon }}</v-icon>
                <span>{{ $t('common.' + states[state].action ) }}</span>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script setup>

import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import useConfirmDialog from "@/composables/useConfirmDialog";

const props = defineProps(['modelValue', 'collectionCode'])

let states = ref({
  ONLINE: {
    icon: 'mdi-arrow-up-bold-circle',
    color: 'green-darken-1',
    action: 'load'
  },
  OFFLINE: {
    icon: 'mdi-arrow-down-bold-circle',
    color: 'grey-darken-1',
    action: 'unload'
  },
  // DISABLED: {
  //     icon: 'mdi-cancel',
  //     color: 'red-darken-3',
  //     action: 'disable'
  // }
})

let current = computed(() => states.value[props.modelValue])

const emit = defineEmits(['changed'])
let {t} = useI18n()
function changeState(newState) {
  useConfirmDialog(
    {
      title: t('runner.collection.status-to-' + newState.toLowerCase(), {code: props.collectionCode}),
      message: t('runner.collection.confirm-status-to-' + newState.toLowerCase(), {code: props.collectionCode})
    },
    () => emit("changed", newState)
  )
}
</script>