import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

export default createI18n({
    legacy: true,
    allowComposition: true,
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'en',
    globalInjection: true,
    messages
})