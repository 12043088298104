<template>
  Loading ...
</template>
<script setup>
import {useAuth} from '@/composables/useAuth'
import {useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted} from "vue";

const auth = useAuth()
const router = useRouter()

onMounted(async () => {
  try {
    console.log("Renewing access token")
    await auth.$auth.renewToken()
    await router.push('/')
  } catch (error) {
    console.log(error)
  }
})
</script>
