<template>
    <v-card width="720px" class="mx-auto">
        <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title class="white--text">
                <span v-if="record.creation"
                      v-html="$t('referential.records.new-title', {code: dataset.code})"/>
                <span v-else v-html="$t('referential.records.edit-title', {code: record.code})"/>
            </v-toolbar-title>
            <v-spacer/>
            <v-icon class="mr-4" @click="cancel">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
            <CodeTextField v-model="record.code" :edit="record.creation" class="mb-6" :allow-start-by-number="true"/>
            <div v-for="property in properties">
                <YesNoField v-if="property.type === 'BOOLEAN'" v-model="record.properties[property.code].value"
                            :label="property.label"/>
                <v-text-field v-else v-model="record.properties[property.code].value" :label="property.label"
                              :type="property.type" variant="outlined" bg-color="white"/>
            </div>
            <YesNoField v-model="record.disabled" :label="$t('referential.dataset.records.disabled')"/>
            <DatasetRecordFinder v-if="record.disabled" v-model="record.link" :dataset-code="dataset.code"/>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="!record.creation" color="error" @click="remove">{{ $t('common.delete') }}</v-btn>
            <v-spacer/>
            <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" @click="save">{{ $t('common.save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import YesNoField from "@/components/common/YesNoField.vue";
import DatasetRecordFinder from "@/components/referential/DatasetRecordFinder.vue";
import {computed, ref} from "vue";
import useConfirmDialog from "@/composables/useConfirmDialog";
import {useI18n} from "vue-i18n";

const props = defineProps({
  record: {},
  dataset: {}
})

let types = ref(['STRING', 'NUMBER', 'BOOLEAN', 'DATE'])

let properties = computed(() => {
  if (props.dataset && props.dataset.properties) {
    return props.dataset.properties
      .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
  }
  return []
})

properties.value.forEach(property => {
  if (!props.record.properties[property.code]) {
    props.record.properties[property.code] = {value: null}
  }
})

const emit = defineEmits(['canceled', 'saved', 'deleted'])

function cancel() {
  emit("canceled");
}

function save() {
  emit("saved", props.record)
}

let {t} = useI18n()

function remove() {
  useConfirmDialog(
    {
      title: t('referential.records.delete-msg', {code: props.record.code}),
      message: t('referential.records.confirm-delete-msg', {code: props.record.code})
    },
    () => emit("deleted", props.record)
  )
}
</script>