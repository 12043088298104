// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import colors from 'vuetify/lib/util/colors'

// Vuetify
import {createVuetify} from 'vuetify'

const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#F5F5F5',
        strip: '#d6f5d7',
        primary: '#1B5E20',
        secondary: '#E0E0E0',
        error: '#B00020',
        info: '#2196F3',
        action: '#1565C0',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

export default createVuetify(
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    {
        theme: {
            defaultTheme: 'myCustomLightTheme',
            themes: {
                myCustomLightTheme,
            }
        }
    }
)
