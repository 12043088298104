<template>
  <Loading :loading="loading" :text="$t('catalog.variable.loading-variable', {code: route.params.variableReference})"/>
  <v-card-actions>
    <CatalogBreadcrumb :reference="$route.params.variableReference"/>
  </v-card-actions>
  <v-card v-if="variable" class="mt-3" density="compact">
    <v-card-actions>
      <v-spacer/>
      <ComponentHistory :component="variable" :edit="edit"/>
<!--      <VariableRules :variable="variable" :edit="edit"/>-->
      <v-divider vertical style="height: auto" class="mx-3"></v-divider>
      <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                         :tooltip-text="$t('catalog.variable.edit')"/>
      <ButtonWithTooltip icon="mdi-content-copy" color="action" :edit="edit" @clicked="duplicate = true"
                         :tooltip-text="$t('catalog.variable.duplicate.label')"/>
      <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteVariable"
                         :tooltip-text="$t('catalog.variable.delete')"/>
    </v-card-actions>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="4">
          <CodeTextField v-model="variable.reference.code" :edit="false"/>
        </v-col>
        <v-col cols="5">
          <v-text-field v-model="variable.label" label="Label" :readonly="!edit" :variant="edit ? 'outlined' : 'plain'"
                        hide-details :bg-color="edit ? 'white' : ''"/>
        </v-col>
        <v-col cols="2">
          <v-chip color="purple" class="mr-2" label>
            <span style="font-family: monospace, serif">
              {{ $t('catalog.variable.nature.' + variable.nature.toLowerCase()) }}
            </span>
          </v-chip>
          <v-chip color="blue" label>
            <span style="font-family: monospace, serif">
              {{ $t('catalog.variable.type.' + variable.type.toLowerCase()) }}
            </span>
          </v-chip>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="11">
          <v-card v-if="variable.type !== 'COMPOSITE'">
            <v-toolbar color="primary">
              <v-toolbar-title>{{ $t('common.definition') }}</v-toolbar-title>
            </v-toolbar>
            <div v-if="variable.type === 'RECORD'">
              <v-row align="center" class="pa-3">
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">
                    {{ $t('catalog.variable.related-dataset') }}
                  </span>
                </v-col>
                <v-col cols="9">
                  <v-chip v-if="!edit">
                    <span>{{ referentialItemTitle(selectedDataset) }}</span>
                    <v-icon size="small" class="ml-2" @click="openReferentialItem(selectedDataset, 'DATASET')">
                      mdi-open-in-new
                    </v-icon>
                  </v-chip>
                  <v-select v-else v-model="selectedDataset" :items="datasets" item-value="code" return-object
                            :item-title="referentialItemTitle" variant="outlined" hide-details bg-color="white"/>
                </v-col>
              </v-row>
              <v-divider class="mx-2"></v-divider>
              <v-row align="center" class="pa-3">
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">
                    {{ $t('catalog.variable.include-classifiers') }}
                  </span>
                </v-col>
                <v-col cols="9">
                  <div v-if="!edit">
                    <v-chip v-for="classifier in selectedClassifiers" :key="classifier.code" class="mr-2">
                      <span>{{ referentialItemTitle(classifier) }}</span>
                      <v-icon size="small" class="ml-2" @click="openReferentialItem(classifier, 'CLASSIFIER')">
                        mdi-open-in-new
                      </v-icon>
                    </v-chip>
                  </div>
                  <v-autocomplete v-else v-model="selectedClassifiers" :items="classifiers" :readonly="!edit" multiple
                                  chips :closable-chips="edit" :variant="edit ? 'outlined' : 'plain'" hide-details
                                  :style="edit ? 'background-color: white' : ''" :item-title="referentialItemTitle"
                                  return-object/>
                </v-col>
              </v-row>
              <v-divider class="mb-4 mx-2"></v-divider>
            </div>
            <ValueOrFormula class="px-3" :label="$t('common.value')" :edit="edit" :type="variable.type"
                            v-model="variable.value" :items="variable.validValues" :multiple="variable.multiple"/>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="11">
          <v-card>
            <v-toolbar color="primary">
              <v-toolbar-title>{{ $tc('common.parameter', 2) }}</v-toolbar-title>
            </v-toolbar>
            <v-row>
              <v-col cols="2">
                <v-tabs v-model="tab" direction="vertical" color="primary">
                  <v-tab
                    value="controls">
                    <v-icon left class="mr-2">
                      mdi-lock-check-outline
                    </v-icon>
                    {{ $t('catalog.variable.value-controls') }}
                  </v-tab>
                  <v-tab value="multiple">
                    <v-icon left class="mr-2">
                      mdi-numeric-1-box-multiple-outline
                    </v-icon>
                    {{ $t('catalog.variable.multiplicity') }}
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col>
                <v-window v-model="tab" style="width: 100%">
                  <v-window-item value="controls">
                    <v-row align="center" justify="center" class="pb-2">
                      <v-col cols="11">
                        <div>
                          <ValueOrFormula :label="$t('catalog.variable.mandatory')" :edit="edit"
                                          v-model="variable.mandatory" type="boolean"/>
                        </div>
                        <div v-if="variable.type === 'NUMBER'">
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.min-value')" v-model="variable.minValue"
                                          :edit="edit" :type="variable.type"/>
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.max-value')" v-model="variable.maxValue"
                                          :edit="edit" :type="variable.type"/>
                        </div>
                        <div v-if="variable.type === 'DATE'">
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.min-date')" v-model="variable.minDate"
                                          :edit="edit" :type="variable.type"/>
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.max-date')" v-model="variable.maxDate"
                                          :edit="edit" :type="variable.type"/>
                        </div>
                        <div v-if="variable.type === 'NUMBER'">
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.pace')" v-model="variable.pace"
                                          :edit="edit" :type="variable.type"/>
                          <v-divider class="mb-4"></v-divider>
                          <Value :label="$t('catalog.variable.scale')" v-model="variable.scale"
                                 :edit="edit" type="number" class="mb-2"/>
                          <div v-if="variable.scale > 0">
                            <v-divider class="mb-4"></v-divider>
                            <Value :label="$t('catalog.variable.rounding-mode')" v-model="variable.roundingMode"
                                   :edit="edit" type="string" :items="roundingModes"/>
                          </div>
                        </div>
                        <div v-if="variable.type === 'STRING'">
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.min-length')" v-model="variable.minLength"
                                          :edit="edit" type="number"/>
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.max-length')" v-model="variable.maxLength"
                                          :edit="edit" type="number"/>
                          <v-divider class="mb-4"></v-divider>
                          <Value :label="$t('catalog.variable.pattern')" v-model="variable.valuePattern"
                                 :edit="edit" :type="variable.type" class="mb-2"/>
                          <v-divider class="mb-4"></v-divider>
                          <v-row align="center">
                            <v-col cols="3">
                              <span class="text-subtitle-1 font-weight-medium d-flex align-center mb-2 ml-2">
                                {{ $t('catalog.variable.valid-values') }}
                              </span>
                            </v-col>
                            <v-col cols="9">
                              <div v-if="!edit">
                                <v-chip v-for="validValue in variable.validValues" class="mr-1 mb-1">
                                  {{ validValue }}
                                </v-chip>
                              </div>
                              <v-combobox v-else v-model="variable.validValues" :items="[]" multiple chips hide-details
                                          closable-chips variant="outlined" style="background-color: white"/>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                  <v-window-item value="multiple">
                    <v-row align="center" justify="center">
                      <v-col cols="11">
                        <v-row align="center" class="mt-2">
                          <v-col cols="3">
                            <span class="text-subtitle-1 font-weight-medium mb-2 ml-2">
                              {{ $t('catalog.variable.multiple') }}
                            </span>
                          </v-col>
                          <v-col cols="9">
                            <v-switch v-model="variable.multiple" :disabled="!edit" color="primary" inset hide-details
                                      :label="`${variable.multiple? $t('common.yes') : $t('common.no')}`"/>
                          </v-col>
                        </v-row>
                        <div v-if="variable.multiple">
                          <div v-if="variable.type === 'COMPOSITE'">
                            <v-divider class="mb-2"></v-divider>
                            <v-row align="center">
                              <v-col cols="3">
                                <span class="text-subtitle-1 font-weight-medium mb-2 ml-2">
                                  {{ $t('catalog.variable.loop-over-other-variables') }}
                                </span>
                              </v-col>
                              <v-col cols="9">
                                <v-switch v-model="looping" hide-details :disabled="!edit" color="primary" inset
                                          :label="`${looping? $t('common.yes') : $t('common.no')}`"/>
                              </v-col>
                            </v-row>
                            <div v-if="looping">
                              <v-row align="center">
                                <v-col cols="3">
                                  <span class="text-subtitle-1 font-weight-medium mb-2 ml-2">
                                    {{ $t('catalog.variable.over') }}
                                  </span>
                                </v-col>
                                <v-col cols="9">
                                  <v-row v-for="(loop, i) in loopOver" :class="i === 0 ? 'ma-0' : 'ma-0 border-t'" align="center">
                                    <v-col>
                                      <v-text-field v-if="!edit" v-model="loop.var" variant="plain" density="compact"
                                                    :label="$t('catalog.variable.label')" readonly hide-details/>
                                      <v-select v-else v-model="loop.var" :items="availableLoopingVars" hide-details
                                                variant="outlined" bg-color="white"
                                                :label="$t('catalog.variable.label')"/>
                                    </v-col>
                                    <v-col>
                                      <CodeTextField v-model="loop.code" :edit="edit"/>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-btn v-if="edit" icon="mdi-close" size="small" color="error" variant="text"
                                             @click="loopOver.splice(i, 1)"></v-btn>
                                    </v-col>
                                  </v-row>
                                  <div class="d-flex justify-center">
                                    <v-btn v-if="edit" variant="text" color="primary" @click="addLoopingVariable">
                                      {{ $t('catalog.variable.add-looping-variable') }}
                                    </v-btn>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                          <div v-if="!looping">
                            <v-divider class="mb-4"></v-divider>
                            <ValueOrFormula :label="$t('catalog.variable.min-occurrence')" :edit="edit"
                                            v-model="variable.minOccurrence" type="number"/>
                            <v-divider class="mb-4"></v-divider>
                            <ValueOrFormula :label="$t('catalog.variable.max-occurrence')" :edit="edit"
                                            v-model="variable.maxOccurrence" type="number"/>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="edit" color="error" @click="cancelEditing">{{ $t('common.cancel') }}</v-btn>
      <v-btn v-if="edit" color="primary" @click="updateVariable">{{ $t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
  <Properties v-if="variable" :variable="variable"></Properties>
  <SubVariables v-if="variable && variable.type === 'COMPOSITE'" :reference="reference" :label="variable.label"
                v-model="variables"/>
  <RecordSubVariables v-if="variable && variable.type === 'RECORD'" :key="recordSubVariablesKey" :variable="variable"/>
  <v-dialog v-model="duplicate" persistent>
    <DuplicateVariable :to-duplicate="variable" @canceled="duplicate = false"
                       @duplicated="duplicateVariable"></DuplicateVariable>
  </v-dialog>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import {computeVariableReference, getCollectionCode} from "@/util"
import {useRoute, useRouter} from "vue-router";
import {computed, ref, watch} from "vue";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useReferentialApi} from "@/composables/useReferentialApi";
import useConfirmDialog from "@/composables/useConfirmDialog";
import {useI18n} from "vue-i18n";
import Loading from "@/components/common/Loading.vue";
import CatalogBreadcrumb from "@/components/catalog/CatalogBreadcrumb.vue";
import Properties from "@/components/catalog/Properties.vue";
import SubVariables from "@/components/catalog/SubVariables.vue";
import RecordSubVariables from "@/components/catalog/RecordSubVariables.vue";
import ComponentHistory from "@/components/catalog/ComponentHistory.vue";
import VariableRules from "@/components/catalog/VariableRules.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import ValueOrFormula from "@/components/catalog/ValueOrFormula.vue";
import Value from "@/components/catalog/Value.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import DuplicateVariable from "@/components/catalog/DuplicateVariable.vue";
import {useCollectionStore} from "@/store/collection";

let route = useRoute()

let variable = ref(null)
let variables = ref([])
let edit = ref(false)
let roundingModes = ref(['UP', 'DOWN', 'CEILING', 'FLOOR', 'HALF_UP', 'HALF_DOWN', 'HALF_EVEN', 'UNNECESSARY'])
let tab = ref('mandatory')
let looping = ref(false)
let loopOver = ref([{var: undefined, code: undefined}])
let availableLoopingVars = ref([])
let duplicate = ref(false)
let datasets = ref([])
let classifiers = ref([])
let selectedDataset = ref(null)
let selectedClassifiers = ref([])
let recordSubVariablesKey = ref(0)
let loading = ref(true)

let reference = computed(() => computeVariableReference(variable.value))

let catalogApi = useCatalogApi()
let referentialApi = useReferentialApi()
let collectionStore = useCollectionStore()

catalogApi.$variable.find(route.params.variableReference)
  .then(result => {
    variable.value = result;
    collectionStore.setCurrentVariable(reference.value)
    if (!collectionStore.currentCollection) {
      collectionStore.setCurrentCollection(getCollectionCode(reference.value))
    }
    if (variable.value.loopOver) {
      looping.value = true;
      loopOver.value = [];
      new Map(Object.entries(variable.value.loopOver)).forEach((k, v) => loopOver.value.push({var: k, code: v}));
    }
    if (variable.value.type === "COMPOSITE") {
      catalogApi.$variable.listCompositeVariables(route.params.variableReference)
        .then(result2 => variables.value = result2)
      catalogApi.$variable.getAvailableLoopingVariablesReferences(route.params.variableReference)
        .then(result3 => availableLoopingVars.value = result3)
    }
    if (variable.value.type === 'RECORD') {
      referentialApi.$dataset.list()
        .then(result => {
          datasets.value = result
          selectedDataset.value = datasets.value.find(dataset => dataset.code === variable.value.datasetCode)
        })
    }
  })
  .finally(() => loading.value = false)

watch(selectedDataset, (value) => {
  if (value) {
    referentialApi.$classifier.listByDataset(value.code)
      .then(result => {
        selectedClassifiers.value = result.filter(classifier => variable.value.classifiers?.includes(classifier.code))
        classifiers.value = result
      })
  }
})

let {t} = useI18n()
let router = useRouter()

function deleteVariable() {
  let confirmation = variable.value.type === 'COMPOSITE' ? variable.value.reference.code : undefined
  useConfirmDialog(
    {
      title: t('catalog.variable.delete-msg', {label: variable.value.label}),
      message: t('catalog.variable.confirm-delete-msg', {label: variable.value.label}),
      confirmation: confirmation
    },
    () => {
      catalogApi.$variable.deleteVariable(reference.value)
        .then(deleteOK => {
          if (deleteOK) {
            toast.warning(t('catalog.variable.deleted', {label: variable.value.label}))
            if (variable.value.reference.parent && variable.value.reference.parent.includes('/')) {
              router.push({name: 'variable', params: {variableReference: variable.value.reference.parent}})
            } else {
              router.push({name: 'collection', params: {collectionCode: variable.value.reference.parent}})
            }
          } else {
            toast.error(t('catalog.variable.error-on-delete', {label: variable.value.label}))
          }
        })
    }
  )
}

function cancelEditing() {
  edit.value = !edit.value;
}

function updateVariable() {
  if (looping.value && loopOver.value) {
    const tmpMap = new Map();
    loopOver.value.filter(loop => loop.var).forEach(loop => tmpMap.set(loop.code, loop.var));
    variable.value.loopOver = Object.fromEntries(tmpMap)
  } else {
    loopOver.value = [{var: undefined, code: undefined}];
    variable.value.loopOver = undefined;
  }
  if (variable.value.type === 'RECORD') {
    variable.value.datasetCode = selectedDataset.value.code
    variable.value.classifiers = selectedClassifiers.value.map(classifier => classifier.code);
  }
  if (variable.value.values && variable.value.values === 'all') {
    variable.value.values = []
    variable.value.loadAll = true
  }
  console.log(variable.value)
  catalogApi.$variable.updateVariable(variable.value)
    .then(() => {
      toast.success(t('catalog.variable.saved', {label: variable.value.label}))
      recordSubVariablesKey.value += 1
    })
    .finally(() => edit.value = false)
}

function addLoopingVariable() {
  loopOver.value.push({var: undefined, code: undefined})
}

function duplicateVariable(duplicateInfos) {
  catalogApi.$variable.duplicateVariable(reference.value, duplicateInfos)
    .then(created => {
      const createdReference = computeVariableReference(created)
      toast.success(t('catalog.variable.duplicated', {origin: variable.value.label, label: created.label}))
      router.push({name: 'variable', params: {variableReference: createdReference}})
    })
}

function referentialItemTitle(item) {
  if (item) {
    return `${item.code} - ${item.label}`
  }
}

function openReferentialItem(item, nature) {
  let route = undefined
  if (nature === 'CLASSIFIER') {
    route = router.resolve({name: 'classifier', params: {classifierCode: item.code}});
  } else if (nature === 'DATASET') {
    route = router.resolve({name: 'dataset', params: {datasetCode: item.code}});
  }
  if (route) {
    window.open(route.href, '_blank');
  }
}
</script>