<template>
  <v-dialog v-model="dialog" @keydown.esc="cancel" persistent>
    <v-card width="600px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="ma-6">
        <v-row justify="center">
          {{ message }}
        </v-row>
        <v-row v-if="confirmation" class="mt-8" align="center">
          <span class="mr-2" v-html="$t('common.confirmation-text', {text: confirmation})" />
          <v-text-field v-model:model-value="confirmationTyped" hide-details variant="outlined" density="compact" :error="confirmationError" bg-color="white"/>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="error" text @click.native="cancel">{{ $t('common.cancel') }}</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">{{ $t('common.yes')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    confirmation: null,
    confirmationTyped: null,
    confirmationError: false,
    options: {
      width: 290,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, confirmation = undefined) {
      this.dialog = true
      this.title = title
      this.message = message
      this.confirmation = confirmation
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (!this.confirmation || this.confirmation === this.confirmationTyped) {
        this.resolve(true)
        this.dialog = false
        this.confirmationError = false
        this.confirmationTyped = null
      } else {
        this.confirmationError = true
      }
    },
    cancel() {
      this.confirmationTyped = null;
      this.confirmationError = false;
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>