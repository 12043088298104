import { acceptHMRUpdate, defineStore } from 'pinia'
import {computed, ref} from "vue";

export const useAuthStore = defineStore('auth', () => {
    const authUser = ref(null)
    const accessToken = computed(() => authUser.value?.access_token ?? '')
    const isLoggedIn = computed(() => authUser.value ? !authUser.value.expired : false)
    const isGuest = computed(() => authUser.value?.profile['ruleshake/roles'].includes('GUEST') ?? false)

    const setUser = (user) => {
        authUser.value = user
    }

    const clearUserSession = () => {
        authUser.value = null
    }

    return {
        authUser,
        accessToken,
        isLoggedIn,
        isGuest,
        setUser,
        clearUserSession,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}