import {UserManager, WebStorageStateStore} from 'oidc-client-ts'

export default class AuthService {

    constructor() {
        const settings = {
            authority: `${import.meta.env.VITE_AUTH_AUTHORITY}`,
            client_id: `${import.meta.env.VITE_AUTH_CLIENT_ID}`,
            client_secret: `${import.meta.env.VITE_AUTH_CLIENT_SECRET}`,
            redirect_uri: `${window.location.origin}/auth-callback`,
            silent_redirect_uri: `${window.location.origin}/auth-silent-refresh`,
            post_logout_redirect_uri: `${window.location.origin}`,
            response_type: 'code',
            scope: 'openid profile email',
            userStore: new WebStorageStateStore(),
            loadUserInfo: true,
            extraQueryParams: {
                audience: 'ruleshake-services'
            },
            // metadata: {
            //     authorization_endpoint: 'https://ruleshake.eu.auth0.com/authorize',
            //     end_session_endpoint: 'https://ruleshake.eu.auth0.com/v2/logout',
            //     token_endpoint: 'https://ruleshake.eu.auth0.com/oauth/token',
            //     userinfo_endpoint: 'https://ruleshake.eu.auth0.com/userinfo',
            //     revocation_endpoint: 'https://ruleshake.eu.auth0.com/oauth/revoke'
            // }
        }
        this.userManager = new UserManager(settings);
    }

    signInRedirect() {
        return this.userManager.signinRedirect()
    }

    signInCallback() {
        return this.userManager.signinCallback()
    }

    renewToken() {
        return this.userManager.signinSilentCallback()
    }

    logout() {
        return this.userManager.signoutRedirect()
            .catch(err => {
                if (err.message.toLowerCase() !== 'no end session endpoint') throw err;
                // this is most likely auth0, so let's try their logout endpoint.
                // @see: https://auth0.com/docs/api/authentication#logout
                // this is dirty and hack and reaches into guts of the oidc client
                // in ways I'd prefer not to.. but auth0 has this annoying non-conforming
                // session termination.
                const authority = this.userManager.settings.authority;
                if (authority.includes('auth0.com')) {
                    window.location = new URL(`/v2/logout?client_id=${
                        this.userManager.settings.client_id}&returnTo=${encodeURIComponent(
                        this.userManager.settings.post_logout_redirect_uri)}`, authority).toString()
                } else throw err
            })
    }

    getUser() {
        return this.userManager.getUser()
    }
}