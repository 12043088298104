<template>
  <div v-if="variable.type === 'COMPOSITE'" class="mt-2">
    <v-expansion-panels v-if="insideExpansionPanel" class="mb-4">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <span class="text-subtitle-1 font-weight-medium">{{ variable.label }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div v-if="variable.multiple" class="my-2">
            <v-card v-for="nbInstance in nbInstances" class="mt-4" elevation="2">
              <v-card-title>
                <v-row class="ma-2">
                  <span class="text-subtitle-1 font-weight-medium">{{ variable.label }} {{ nbInstance }}</span>
                  <v-spacer/>
                  <v-btn v-if="nbInstance === nbInstances" icon="mdi-delete" color="error" variant="tonal" @click="removeInstance"></v-btn>
                </v-row>
              </v-card-title>
              <v-card-text>
                <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable"
                                    :parent="computeVariableReference(nbInstance - 1)" @updated="handleUpdated"
                                    @instance-removed="handleInstanceRemoved"/>
              </v-card-text>
            </v-card>
            <v-row class="my-6" justify="center">
              <v-btn @click="nbInstances++" color="primary">
                {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
              </v-btn>
            </v-row>
          </div>
          <div v-else class="my-2">
            <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable" :parent="computeVariableReference()"
                                @updated="handleUpdated" @instance-removed="handleInstanceRemoved"/>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else-if="variable.multiple" class="ma-2">
      <v-card v-for="nbInstance in nbInstances" class="mt-4" elevation="2">
        <v-card-title>
          <v-row class="ma-2">
            <span class="text-subtitle-1 font-weight-medium">{{ variable.label }} {{ nbInstance }}</span>
            <v-spacer/>
            <v-btn v-if="nbInstance === nbInstances" icon="mdi-delete" color="error" variant="tonal" @click="removeInstance"></v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable" :parent="computeVariableReference(nbInstance - 1)"
                              @updated="handleUpdated" @instance-removed="handleInstanceRemoved"/>
        </v-card-text>
      </v-card>
      <v-row class="mt-6 mb-1" justify="center">
        <v-btn @click="nbInstances++" color="primary">
          {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
        </v-btn>
      </v-row>
    </div>
    <div v-else class="ma-2">
      <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable" :parent="computeVariableReference()"
                          @updated="handleUpdated" @instance-removed="handleInstanceRemoved"/>
    </div>
  </div>
  <v-row v-else-if="variable.type === 'BOOLEAN'" align="center">
    <v-col cols="3">
      <span class="text-subtitle-1">{{ variable.label }}</span>
    </v-col>
    <v-col>
      <div v-for="index in values.keys()">
        <v-radio-group v-model="values[index]" :id="computeVariableReference(index)" inline @input="handleValue" hide-details mandatory>
          <v-radio :label="$t('common.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.no')" :value="false"></v-radio>
        </v-radio-group>
      </div>
    </v-col>
  </v-row>
  <v-row v-else-if="variable.type === 'RECORD'" align="center">
    <v-col cols="3">
      <span class="text-subtitle-1">{{ variable.label }}</span>
    </v-col>
    <v-col>
      <div v-for="index in values.keys()" :class="index > 0 ? 'mt-2' : ''">
        <DatasetRecordFinder v-model="values[index]" :dataset-code="variable.datasetCode"
                             @update:modelValue="handleValueByIndex(index, values[index])"/>
      </div>
      <v-btn v-if="variable.multiple" @click="addInstance" color="primary" variant="text">
        {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
      </v-btn>
    </v-col>
  </v-row>
  <v-row v-else align="center">
    <v-col cols="3">
      <span class="text-subtitle-1">{{ variable.label }}</span>
    </v-col>
    <v-col>
      <div v-for="index in values.keys()" :class="index > 0 ? 'mt-2' : ''">
        <v-select v-if="variable.validValues" v-model="values[index]" :items="variable.validValues" :clearable="index === values.length - 1"
                  :id="computeVariableReference(parent, variable, index)" variant="outlined" bg-color="white" hide-details
                  @update:modelValue="handleValueByIndex(index, values[index])"/>
        <v-text-field v-else v-model="values[index]" :id="computeVariableReference(index)" :type="variable.type"
                      :step="variable.pace?.value" :min="variable.minValue?.value" :max="variable.maxValue?.value"
                      variant="outlined" bg-color="white" hide-details @input="handleValue"></v-text-field>
      </div>

      <v-btn v-if="variable.multiple" @click="addInstance" color="primary" variant="text">
        {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script setup>
import DatasetRecordFinder from "@/components/referential/DatasetRecordFinder.vue";
import {computed, ref, watch} from "vue";
import {useSimulationStore} from "@/store/simulation";

const props = defineProps({
  parent: {
    type: String,
    default: ''
  },
  variable: {}
})

let nbInstances = ref(1)
let values = ref([undefined])

let simulationStore = useSimulationStore()

let insideExpansionPanel = computed(() => props.variable.reference.parent.includes("/"))
let selectedCase = computed(() => simulationStore.selectedSimulationCase)

computeValues()
watch(selectedCase, () => computeValues())

function subVariables(composite) {
  return simulationStore.getChildrenDefinitions(composite.reference.parent + "/" + composite.reference.code)
    .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
}

function handleValue(e) {
  if (props.variable.multiple) {
    handleUpdated({
      reference: e.target.id,
      value: e.target.value,
      type: props.variable.type
    })
  } else {
    handleUpdated({
      reference: (props.parent ? props.parent + "/" : '') + props.variable.reference.code,
      value: e.target.value,
      type: props.variable.type
    })
  }
}
function handleValueByIndex(index, value) {
  if (value) {
    handleUpdated({
      reference: computeVariableReference(index),
      value: value,
      type: props.variable.type
    })
  } else {
    removeInstance()
  }
}

function addInstance() {
  nbInstances.value++;
  values.value[nbInstances.value - 1] = getValueFromInputs(nbInstances.value - 1)
}

function removeInstance() {
  handleInstanceRemoved(computeVariableReference(nbInstances.value - 1));
  nbInstances.value--;
  values.value.pop()
}

const emit = defineEmits(['updated', 'instance-removed'])

function handleUpdated(inputVariable) {
  emit("updated", inputVariable)
}

function handleInstanceRemoved(variableReference) {
  emit("instance-removed", variableReference)
}

function computeVariableReference(instance = undefined) {
  if (instance >= 0 && props.variable.multiple) {
    return (props.parent ? props.parent + "/" : '') + props.variable.reference.code + "[" + instance + "]"
  }
  return (props.parent ? props.parent + "/" : '') + props.variable.reference.code
}

function getValueFromInputs(instance) {
  if (selectedCase.value && selectedCase.value.inputs) {
    let found = selectedCase.value.inputs.find(input => input.reference === computeVariableReference(instance));
    if (found) {
      return found.value
    }
  }
  return undefined
}

function computeValues() {
  nbInstances.value = 1
  values.value = [props.variable.value?.value]
  if (selectedCase.value) {
    let variableReference = computeVariableReference();
    let nbInstanceVariableFromSelectedCase = simulationStore.getNbInstanceVariable(variableReference);
    nbInstances.value = nbInstanceVariableFromSelectedCase || 1;
    [...Array(nbInstances.value).keys()].forEach(i => values.value[i] = getValueFromInputs(i))
  }
}
</script>