<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="usages" eager>
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">{{ $t('runner.collection.code-snippet') }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="ma-0 pa-0" eager>
        <v-tabs v-model="tab" color="primary">
          <v-tab value="curl">cURL</v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item value="curl" class="pt-3">
            <v-ace-editor v-if="!isGuest" :value="getCurl()" style="min-height: 125px" :readonly="true" :print-margin="false" :options="editorOptions"/>
            <v-alert v-else type="warning" prominent variant="tonal">
              {{ $t('runner.collection.code-snippet-not-allowed') }}
            </v-alert>
          </v-window-item>
        </v-window>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup>
import {ref} from "vue";
import {VAceEditor} from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ace';
import {useAuthStore} from "@/store/auth";

const props = defineProps(['collectionCode'])

let editorOptions = ref({
  highlightActiveLine: false,
  highlightGutterLine: false,
  minLines: 4,
  maxLines: 20,
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  enableSnippets: true,
  fontSize: '10pt'
})

let panel = ref(["usages"])
let tab = ref(null)

const RUNNER_URL = import.meta.env.VITE_RULESHAKE_RUNNER_URL;
const authStore = useAuthStore()
let accessToken = authStore.accessToken;
let isGuest = ref(authStore.isGuest)

function getCurl() {
  const date = new Date()
  return `curl --location '${RUNNER_URL}/v1/evaluations' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer ${accessToken}' \\
--data '{
  "requestTime": "${date.toISOString()}",
  "collectionCode": "${props.collectionCode}",
  "inputs": []
}'`
}
</script>