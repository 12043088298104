<template>
  Loading...
</template>
<script setup>
import {useAuth} from '@/composables/useAuth'
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";
import {onMounted} from "vue";

const auth = useAuth()
const router = useRouter();
const authStore = useAuthStore()

onMounted(async () => {
  try {
    authStore.clearUserSession()
    const user = await auth.$auth.getUser()
    if (user) {
      await auth.$auth.logout()
    }
    await router.push("/login")
  } catch (error) {
    console.log(error)
  }
})
</script>
