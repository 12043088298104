<template>
  <v-text-field v-model="model" :label="label" :readonly="!edit" :variant="edit ? 'outlined' : 'plain'" hide-details
                :bg-color="edit ? 'white' : ''" v-on:keypress="checkCode" style="font-family: Consolas, Menlo, Courier, monospace"/>
</template>
<script setup>

import {computed} from "vue";

const props = defineProps({
  label: {
    default: 'Code'
  },
  modelValue: null,
  edit: false,
  allowStartByNumber: false
})

const emit = defineEmits(['update:modelValue'])

let model = computed({
  get() { return props.modelValue },
  set(newValue) { emit('update:modelValue', newValue.toUpperCase()) }
})

function checkCode(e) {
  let regex = /^\w+$/
  if (!props.modelValue && !props.allowStartByNumber) {
    regex = /^[A-Za-z]+$/
  }
  let char = String.fromCharCode(e.keyCode); // Get the character
  if (regex.test(char)) return true; // Match with regex
  else e.preventDefault(); // If not match, don't add to input text
}
</script>