<template>
    <v-card width="800px" class="mx-auto">
        <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title class="white--text">
                <span>{{ $t('referential.records.import.title') }}</span>
            </v-toolbar-title>
            <v-spacer/>
            <v-icon class="mr-4" @click="cancel">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
            <v-file-input v-model="files" accept="text/csv" :label="$t('referential.records.import.file')"
                          variant="outlined" hide-details show-size class="ml-4 mr-2 pt-2" bg-color="white"/>
            <v-alert type="info" color="primary" prominent variant="outlined" class="mt-4 mx-2">
                <span v-html="$t('referential.records.import.alert')"/>
            </v-alert>
            <v-select v-model="importMode" :items="importModes" :label="$t('referential.records.import.mode')"
                      variant="outlined" hide-details class="mt-4 mx-2" bg-color="white" return-object
                      :item-title="i => $t('referential.records.import.' + i.toLowerCase())"
            />
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn color="error" @click="$emit('canceled')">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" @click="importRecords">{{ $t('common.import.label') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script setup>
import {ref} from "vue";

const emit = defineEmits(['canceled', 'imported'])

const files = ref(null)
const importMode = ref('MERGE')
const importModes = ['MERGE', 'OVERRIDE']

const importRecords = () => {
    emit('imported', {
        file: files.value[0],
        mode: importMode.value
    })
}
</script>