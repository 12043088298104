<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.dataset.properties.label') }}
          ({{ dataset && dataset.properties && dataset.properties.length > 0 ? dataset.properties.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="my-2">
              <v-spacer/>
              <v-btn v-if="drag" color="warning" class="mr-2" @click="saveNewOrder">
                {{ $t('referential.dataset.properties.save-new-order') }}
              </v-btn>
              <v-btn color="primary" @click="openProperty=true">
                {{ $t('referential.dataset.properties.add') }}
              </v-btn>
            </v-row>
          </v-card-title>
          <div v-if="properties && properties.length > 0">

            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold ml-6">{{ $t('common.order') }}</span>
              </v-col>
              <v-col cols="2">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.code') }}</span>
              </v-col>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ $t('referential.dataset.properties.type.label') }}
                </span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ $t('referential.dataset.properties.filterable') }}
                </span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ $t('referential.dataset.properties.displayable') }}
                </span>
              </v-col>
              <v-col>
              </v-col>
            </v-row>
            <div v-for="element in properties">
              <v-row align="center" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col>
                  <v-icon class="handle mr-2" size="small">mdi-drag-horizontal-variant</v-icon>
                  <span class="text-subtitle-1">{{ element.orderIndex }}</span>
                </v-col>
                <v-col cols="2">
                  <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.code }}</strong>
                </v-col>
                <v-col cols="4">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col>
                  <v-chip color="purple" label>
                    <span style="font-family: monospace, serif">
                      {{ $t('referential.dataset.properties.type.' + element.type.toLowerCase()) }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col>
                  <v-chip color="blue" label><span style="font-family: monospace, serif">{{ element.showInFilter }}</span></v-chip>
                </v-col>
                <v-col>
                  <v-chip color="blue" label><span style="font-family: monospace, serif">{{ element.showInTable }}</span></v-chip>
                </v-col>
                <v-col class="d-flex flex-row justify-end">
                  <v-btn icon="mdi-pencil" size="small" color="primary" variant="text" @click="editProperty(element)" />
                  <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2" @click="deleteProperty(element)" />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="openProperty" persistent>
    <DatasetProperty :dataset-code="dataset.code" :property="propertyToEdit" @canceled="clearPropertyDialog"
                     @saved="saveProperty" @deleted="deleteProperty" />
  </v-dialog>
</template>
<script setup>
import { toast } from 'vue3-toastify';
import DatasetProperty from "@/components/referential/DatasetProperty.vue";
import {ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useI18n} from "vue-i18n";
import useConfirmDialog from "@/composables/useConfirmDialog";

const props = defineProps(['dataset'])

let drag = ref(false)
let panel = ref([])
let properties = ref([])
let openProperty = ref(false)
let propertyToEdit = ref({
  creation: true
})

sortProperties()

let referentialApi = useReferentialApi()
let {t} = useI18n()

function saveNewOrder() {
  drag.value = false;
  const newOrder = new Map();
  for (const [index, value] of properties.value.entries()) {
    newOrder.set(value.code, index + 1);
  }
  referentialApi.$dataset.saveNewPropertiesOrder(dataset.value.code, newOrder)
    .then(saved => {
      props.dataset.properties = saved.properties
      sortProperties()
      toast.success(t('referential.dataset.properties.new-order-saved'))
    })
}

function editProperty(property) {
  propertyToEdit.value = property;
  openProperty.value = true;
}

function saveProperty(property) {
  let promise;
  if (property.creation) {
    promise = referentialApi.$dataset.addProperty(props.dataset.code, propertyToEdit.value)
  } else {
    promise = referentialApi.$dataset.updateProperty(props.dataset.code, propertyToEdit.value)
  }
  promise.then(saved => {
    props.dataset.properties = saved.properties
    sortProperties()
    toast.success(t('referential.dataset.properties.' + (property.creation ? 'created' : 'updated'), {code: property.code}))
  })
    .finally(() => clearPropertyDialog());
}

function deleteProperty(property) {
  useConfirmDialog(
    {
      title: t('referential.dataset.properties.delete-msg', {code: property.code}),
      message: t('referential.dataset.properties.confirm-delete-msg', {code: property.code})
    },
    () => referentialApi.$dataset.deleteProperty(props.dataset.code, property.code)
      .then(saved => {
        props.dataset.properties = saved.properties
        sortProperties()
        toast.warning(t('referential.dataset.properties.deleted', {code: property.code}))
      })
  )
}

function clearPropertyDialog() {
  openProperty.value = false;
  propertyToEdit.value = {
    creation: true
  };
}

function sortProperties() {
  if (props.dataset && props.dataset.properties) {
    properties.value = props.dataset.properties.sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
  }
}
</script>