import BaseApi from "@/services/api/BaseApi";

export default class ClassifierApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    list() {
        return this.call("/referential-api/v1/classifiers", {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.list'
            }
        })
    }

    listByDataset(datasetCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/classifiers`, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.list-classifiers',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    create(classifier) {
        return this.call("/referential-api/v1/classifiers", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(classifier),
            action: {
                key: 'referential.classifier.actions.create',
                params: {
                    code: classifier.code
                }
            }
        })
    }

    find(classifierCode) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}`, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.find',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    update(classifier) {
        return this.call(`/referential-api/v1/classifiers/${classifier.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(classifier),
            action: {
                key: 'referential.classifier.actions.update',
                params: {
                    code: classifier.code
                }
            }
        })
    }

    delete(classifierCode) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.classifier.actions.delete',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    addProperty(classifierCode, property) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/properties`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.classifier.actions.add-property',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    updateProperty(classifierCode, property) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/properties/${property.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.classifier.actions.update-property',
                params: {
                    code: classifierCode,
                    propertyCode: property.code
                }
            }
        })
    }

    deleteProperty(classifierCode, propertyCode) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/properties/${propertyCode}`, {
            responseFormat: 'json',
            method: 'DELETE',
            action: {
                key: 'referential.classifier.actions.delete-property',
                params: {
                    code: classifierCode,
                    propertyCode: propertyCode
                }
            }
        })
    }

    addRecord(classifierCode, record) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/records`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.classifier.actions.add-record',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    updateRecord(classifierCode, record) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/records/${record.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.classifier.actions.update-record',
                params: {
                    code: classifierCode,
                    recordCode: record.code
                }
            }
        })
    }

    deleteRecord(classifierCode, recordCode) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/records/${recordCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.classifier.actions.delete-record',
                params: {
                    code: classifierCode,
                    recordCode: recordCode
                }
            }
        })
    }

    searchRecords(classifierCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/classifiers/${classifierCode}/records`
        if (query) {
            url += "?" + query
        }
        return this.call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.search-records',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    propertiesValues(classifierCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/classifiers/${classifierCode}/values`
        if (query) {
            url += "?" + query
        }
        return this.call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.search-values',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    resolveClassifierRecord(classifierCode, recordCode) {
        return this.call(`/referential-api/v1/classifiers/${classifierCode}/resolve?recordCode=${recordCode}`, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.resolve',
                params: {
                    code: classifierCode
                }
            }
        })
    }

    exportRecords(classifierRecord) {
        return this.call(`/referential-api/v1/classifiers/${classifierRecord}/records/export`, {
            responseFormat: 'blob',
            method: 'POST',
            action: {
                key: 'referential.classifier.actions.export-records',
                params: {
                    code: classifierRecord,
                }
            }
        })
    }

    importRecords(classifierRecord, file, mode) {
        let formData = new FormData();
        formData.append( 'file', file );
        return this.call(`/referential-api/v1/classifiers/${classifierRecord}/records/import?mode=${mode}`, {
            responseFormat: 'ignore',
            method: 'POST',
            headers: {
                'Accept': 'multipart/form-data'
            },
            body: formData,
            action: {
                key: 'referential.classifier.actions.import-records',
                params: {
                    code: classifierRecord,
                }
            }
        })
    }
}