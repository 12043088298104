<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel value="form" eager>
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">{{ $t('catalog.simulation.form') }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="ma-0 pa-0" eager>
        <v-tabs v-model="tab" color="primary">
          <v-tab v-for="composite in composites" :value="composite.reference.code">
            {{ composite.reference.code }}
          </v-tab>
          <v-tab v-if="others.length" value="others">{{ $t('catalog.simulation.others') }}</v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item v-for="composite in composites" :value="composite.reference.code" :eager="true">
            <SimulationVariable :variable="composite" @updated="handleUpdated"
                                @instance-removed="handleInstanceRemoved"/>
          </v-window-item>

          <v-window-item v-if="others" value="others">
            <div class="mt-2">
              <div v-for="other in others">
                <SimulationVariable :variable="other" @updated="handleUpdated"
                                    @instance-removed="handleInstanceRemoved"/>
              </div>
            </div>
          </v-window-item>
        </v-window>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="px-4" color="action" variant="text" @click="saveCase = !saveCase"
                 :disabled="variables.size === 0">
            {{ $t('catalog.simulation.case.save') }}
          </v-btn>
          <v-divider :vertical="true" class="mx-3"></v-divider>
          <v-btn class="px-4" color="error" variant="text" @click="clear">{{ $t('catalog.simulation.clear') }}</v-btn>
          <v-btn class="px-4" color="primary" variant="flat" @click="evaluate">
            {{ $t('catalog.simulation.simulate') }}
          </v-btn>
        </v-card-actions>
        <v-alert v-if="error" prominent type="error" variant="outlined" class="mt-4">
          <span style="white-space: pre-line">{{ error }}</span>
        </v-alert>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel class="mt-2" value="result">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">{{ $t('catalog.simulation.result') }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card class="mt-4">
          <v-card>
            <div v-if="result && result.length">
              <v-tabs v-model="tab2" color="primary">
                <v-tab value="table">{{ $t('catalog.simulation.table') }}</v-tab>
                <v-tab value="request">{{ $t('catalog.simulation.request') }}</v-tab>
                <v-tab value="response">{{ $t('catalog.simulation.response') }}</v-tab>
              </v-tabs>
              <v-window v-model="tab2" class="mt-2">
                <v-window-item value="table">
                  <v-table fixed-header height="500px" hover>
                    <thead>
                    <tr>
                      <th id="reference" class="text-left">{{ $t('common.reference') }}</th>
                      <th id="value" class="text-left">{{ $t('common.value') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="[k, v] in resultTable.entries()" :key="k">
                      <td>{{ k }}</td>
                      <td>{{ v }}</td>
                    </tr>
                    </tbody>
                  </v-table>
                </v-window-item>
                <v-window-item value="request" class="mt-2">
                  <json-viewer :value="Array.from(variables.values())" copyable boxed expanded></json-viewer>
                </v-window-item>
                <v-window-item value="response" class="mt-2">
                  <json-viewer :value="result" copyable boxed expanded></json-viewer>
                </v-window-item>
              </v-window>
            </div>
            <v-card-text v-else class="text-subtitle-1">{{ $t('catalog.simulation.no-result-msg') }}</v-card-text>
          </v-card>
        </v-card>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="saveCase" persistent>
    <v-card width="720px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ $t('catalog.simulation.case.save') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field v-model="caseToSave.code" variant="outlined" :label="$t('catalog.simulation.case.code')"
                      class="mt-2" bg-color="white"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-row class="mr-3">
          <v-spacer></v-spacer>
          <v-btn @click="saveCase = !saveCase" color="error">{{ $t('common.cancel') }}</v-btn>
          <v-btn @click="save" color="primary">{{ $t('common.save') }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <Loading :loading="loading" :text="$t('catalog.simulation.loading-evaluation')"/>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import SimulationVariable from "@/components/catalog/SimulationVariable.vue";
import Loading from "@/components/common/Loading.vue";
import {computed, ref, watch} from "vue";
import {useSimulationStore} from "@/store/simulation";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";

const props = defineProps(['collectionCode'])

let panel = ref('form')
let tab = ref(null)
let tab2 = ref(null)
let variables = ref(new Map())
let result = ref([])
let resultTable = ref(new Map())
let error = ref(null)
let saveCase = ref(false)
let caseToSave = ref({
  code: null,
  inputs: []
})
let loading = ref(false)

let simulationStore = useSimulationStore()
let composites = computed(() => simulationStore.getRootDefinitions()
  .filter(variable => variable.type === 'COMPOSITE')
  .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
)
let others = computed(() => simulationStore.getRootDefinitions()
  .filter(variable => variable.type !== 'COMPOSITE')
  .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
)
const { selectedSimulationCase } = storeToRefs(simulationStore)

watch(selectedSimulationCase, (aSelectedCase) => {
  caseToSave.value.code = aSelectedCase ? aSelectedCase.code : null
  if (aSelectedCase && aSelectedCase.inputs) {
    let result = aSelectedCase.inputs.reduce(function (map, obj) {
      map[obj.reference] = obj;
      return map;
    }, {});
    variables.value = new Map(Object.entries(result))
  }
})

let catalogApi = useCatalogApi()

function evaluate() {
  result.value = null;
  loading.value = true
  catalogApi.$simulation.simulateCollection(props.collectionCode, Array.from(variables.value.values()))
    .then(reponse => {
      error.value = null;
      result.value = reponse
      panel.value = 'result'
      resultTable.value = resultToTable(reponse)
    })
    .catch(e => {
      result.value = null
      error.value = e.detail
    })
    .finally(() => loading.value = false)
}

const emit = defineEmits(['saved', 'cleared'])
let {t} = useI18n()

function save() {
  caseToSave.value.collectionCode = props.collectionCode
  caseToSave.value.inputs = Array.from(variables.value.values())
  catalogApi.$simulation.saveCase(caseToSave.value)
    .then(result => {
      emit("saved", result.code)
      toast.success(t('catalog.simulation.case.saved', {code: caseToSave.value.code}))
    })
    .finally(() => saveCase.value = false)
}

function clear() {
  variables.value = new Map()
  emit("cleared")
}

function handleUpdated(inputVariable) {
  console.log(inputVariable)
  variables.value.set(inputVariable.reference, inputVariable);
}

function handleInstanceRemoved(variableReference) {
  Array.from(variables.value.keys()).forEach(function (key) {
    if (key.startsWith(variableReference)) {
      variables.value.delete(key);
    }
  });
}

function resultToTable(result) {
  let map = new Map()
  if (result) {
    result.forEach(variable => {
      if (variable.type === 'COMPOSITE') {
        map = new Map([...map, ...resultToTable(variable.value)])
      } else if (variable.type === 'RECORD') {
        map = new Map([...map, ...resultToTable(variable.subVariables)])
      } else {
        map.set(variable.runtimeReference, variable.value)
      }
    })
  }
  return new Map([...map.entries()].sort())
}
</script>