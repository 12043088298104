<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('catalog.variable.properties.title') }}
          ({{ variable.properties && variable.properties.length > 0 ? variable.properties.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="mb-4 mt-2">
              <v-spacer/>
              <v-btn color="primary" @click="openProperty=true">
                <v-icon class="mr-2">mdi-plus-thick</v-icon>
                <span>{{ $t('catalog.variable.properties.add') }}</span>
              </v-btn>
            </v-row>
          </v-card-title>
          <div v-if="variable.properties && variable.properties.length > 0">
            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-bold ml-6">{{ $t('common.code') }}</span>
              </v-col>
              <v-col cols="7">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
              </v-col>
            </v-row>
            <div v-for="element in variable.properties">
              <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="4">
                  <strong class="ml-2" style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{
                      element.name
                    }}</strong>
                </v-col>
                <v-col cols="7">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col class="d-flex flex-row">
                  <v-btn icon="mdi-pencil" size="small" color="primary" variant="text"
                         @click="editProperty(element)"></v-btn>
                  <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2"
                         @click="deleteProperty(element)"></v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-alert v-else type="info" prominent variant="outlined">
            <span class="text-subtitle-1 mb-6 mt-3"
                  v-html="$t('catalog.variable.properties.has-no-properties-alert', {label: variable.label})"/>
          </v-alert>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-dialog v-model="openProperty" persistent>
    <Property :parent="variable.reference.parent + '/' + variable.reference.code" :property="propertyToEdit"
              @canceled="cancelPropertyEdition" @saved="saveProperty" @deleted="deleteProperty"></Property>
  </v-dialog>
</template>
<script setup>
import Property from "@/components/catalog/Property.vue";
import {ref} from "vue";
import useConfirmDialog from "@/composables/useConfirmDialog";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";

const props = defineProps({
  variable: {}
})

let edit = ref(false)
let openProperty = ref(false)
let panel = ref([])
let propertyToEdit = ref({
  creation: true,
  valueOrFormula: {}
})

let catalogApi = useCatalogApi()
let {t} = useI18n()

function cancelPropertyEdition() {
  openProperty.value = false;
  propertyToEdit.value = {
    creation: true,
    valueOrFormula: {}
  };
}

function deleteProperty(property) {
  useConfirmDialog(
    {
      title: t('catalog.variable.properties.delete-msg', {name: property.name}),
      message: t('catalog.variable.properties.confirm-delete-msg', {name: property.name})
    },
    () => {
      openProperty.value = false;
      catalogApi.$variable.deleteProperty(props.variable.reference.parent + '/' + props.variable.reference.code, property)
        .then(result => {
          props.variable.properties = props.variable.properties.filter(p => p.name !== property.name)
          toast.warning(t('catalog.variable.properties.deleted', {label: property.label}))
        });
    }
  )
}

function saveProperty(property) {
  openProperty.value = false;
  const varRef = props.variable.reference.parent + '/' + props.variable.reference.code;
  if (property.creation) {
    catalogApi.$variable.addProperty(varRef, property)
      .then(saved => {
        props.variable.properties = saved.properties
        toast.success(t('catalog.variable.properties.saved', {label: property.label}))
      });
  } else {
    catalogApi.$variable.updateProperty(varRef, property)
      .then(saved => {
        props.variable.properties = saved.properties
        toast.success(t('catalog.variable.properties.saved', {label: property.label}))
      });
  }
}

function editProperty(property) {
  propertyToEdit.value = property;
  openProperty.value = true;
}

</script>