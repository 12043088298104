import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'

const useConfirmDialog = (props, okAction, nokAction = () => ({})) => {
    const { reveal, onConfirm, onCancel } = createConfirmDialog(ConfirmationDialog, props)

    reveal()
    onConfirm(okAction)
    onCancel(nokAction)
}

export default useConfirmDialog