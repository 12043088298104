<template>
  <v-row class="my-4 mx-2" align="center">
    <img v-if="code" :src="'/' + code + '-color.png'" alt="catalog" width="64">
    <span class="text-h3 ml-3 font-weight-medium">RuleShake <span class="font-weight-light">{{ moduleName }}</span></span>
  </v-row>
  <v-divider class="my-8"></v-divider>
</template>
<script setup>
import {computed, ref} from "vue";
import {useRouter} from "vue-router";

const props = defineProps(['code'])

let tooltip = ref(false)

let moduleName = computed(() => props.code[0].toUpperCase() + props.code.slice(1))

let router = useRouter()

function help() {
  router.push({ path: 'about', query: { module: props.code }})
}
</script>