import {useAuthStore} from "@/store/auth";
import CollectionApi from "@/services/api/catalog/CollectionApi";
import VariableApi from "@/services/api/catalog/VariableApi";
import SimulationApi from "@/services/api/catalog/SimulationApi";

export const useCatalogApi = () => {
    const authStore = useAuthStore()
    let accessToken = authStore.accessToken;
    return {
        $collection: new CollectionApi(accessToken),
        $variable: new VariableApi(accessToken),
        $simulation: new SimulationApi(accessToken)
    }
}