import {acceptHMRUpdate, defineStore} from "pinia";
import {ref} from "vue";
import {useApi} from "@/composables/useApi";



export const useCollectionStore = defineStore('collection', () => {

    let api = useApi()
    const currentCollection = ref(null)
    const currentVariable = ref(null)
    const autocompletion = ref(new Map())
    const setCurrentCollection = (collection) => {
        currentCollection.value = collection
        api.$support.getAutocompletion(collection)
            .then(map => autocompletion.value = map)
    }

    const setCurrentVariable = (variable) => {
        currentVariable.value = variable
    }

    return {
        currentCollection,
        currentVariable,
        autocompletion,
        setCurrentCollection,
        setCurrentVariable
    }

})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCollectionStore, import.meta.hot))
}