<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="records" eager>
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.records.label') }} ({{ totalElements }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="mt-4 mr-4" align="center">
          <v-spacer/>
          <v-btn v-if="deleteFilteredRecordsEnabled" color="warning" @click="deleteFilteredRecords"
                 class="mr-2">
            {{ $t('referential.dataset.records.delete-filtered-records') }}
          </v-btn>
          <v-menu anchor="bottom end" rounded>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props">
                {{ $t('common.actions') }}
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list density="compact" class="mt-2">
              <v-list-item prepend-icon="mdi-plus-thick" @click="openRecord = true"
                           :title="$t('referential.records.add')"/>
              <v-list-item prepend-icon="mdi-publish" @click="openImportRecords = true"
                           :title="$t('referential.records.import-from-csv')"/>
              <v-list-item prepend-icon="mdi-download" @click="exportDatasetRecords"
                           :title="$t('referential.records.download-as-csv')"/>
              <v-list-item v-if="!deleteFilteredRecordsEnabled" prepend-icon="mdi-delete"
                           :title="$t('referential.dataset.records.enable-delete-filter')"
                           @click="deleteFilteredRecordsEnabled = true"/>
              <v-list-item v-if="deleteFilteredRecordsEnabled" prepend-icon="mdi-delete-off"
                           :title="$t('referential.dataset.records.disable-delete-filter')"
                           @click="deleteFilteredRecordsEnabled = false"/>
            </v-list>
          </v-menu>
        </v-row>
        <DatasetRecordSearch :key="datasetRecordSearchKey" :dataset-code="dataset.code" @selected="selectRecord"
                             @totalElements="setTotalElements" @filter="setFilter"/>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="openRecord" persistent>
    <DatasetRecord :dataset="dataset" :record="selectedRecord" @canceled="clearRecordDialog" @saved="saveRecord"
                   @deleted="deleteRecord"/>
  </v-dialog>
  <v-dialog v-model="openImportRecords" persistent>
    <ImportRecords @canceled="openImportRecords = false" @imported="importDatasetRecords"/>
  </v-dialog>
  <Loading :loading="loading" :text="loadingText"/>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import DatasetRecord from "@/components/referential/DatasetRecord.vue";
import DatasetRecordSearch from "@/components/referential/DatasetRecordSearch.vue";
import Loading from "@/components/common/Loading.vue";
import ImportRecords from "@/components/referential/ImportRecords.vue";
import {ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useI18n} from "vue-i18n";
import useConfirmDialog from "@/composables/useConfirmDialog";

const props = defineProps(['dataset'])

let openRecord = ref(false)
let openImportRecords = ref(false)
let drag = ref(false)
let panel = ref([])
let totalElements = ref('?')
let selectedRecord = ref({
  creation: true,
  properties: {}
})
let datasetRecordSearchKey = ref(0)
let deleteFilteredRecordsEnabled = ref(false)
let filter = ref({})
let loading = ref(false)
let loadingText = ref(null)

clearRecordDialog()

function clearRecordDialog() {
  openRecord.value = false;
  selectedRecord.value = {
    creation: true,
    properties: {}
  };
  if (props.dataset.properties) {
    props.dataset.properties.forEach(property => selectedRecord.value.properties[property.code] = {value: null})
  }
}

function selectRecord(record) {
  selectedRecord.value = record;
  openRecord.value = true;
}

let referentialApi = useReferentialApi()
let {t} = useI18n()

function saveRecord(record) {
  let promise;
  record.datasetCode = props.dataset.code
  if (record.creation) {
    promise = referentialApi.$dataset.addRecord(props.dataset.code, record)
  } else {
    promise = referentialApi.$dataset.updateRecord(props.dataset.code, record)
  }
  promise
    .then(saved => {
      toast.success(t('referential.records.' + (record.creation ? 'created' : 'updated'), {code: record.code}))
      datasetRecordSearchKey.value += 1
    })
    .finally(() => clearRecordDialog());
}

function deleteRecord(record) {
  referentialApi.$dataset.deleteRecord(props.dataset.code, record.code)
    .then(_ => {
      toast.warning(t('referential.records.deleted', {code: record.code}))
      datasetRecordSearchKey.value += 1
    })
    .finally(() => clearRecordDialog());
}

function setTotalElements(total) {
  totalElements.value = total
}

function setFilter(filter) {
  filter.value = filter;
}

function deleteFilteredRecords() {
  useConfirmDialog(
    {
      title: t('referential.dataset.records.mass-deletions-msg'),
      message: t('referential.dataset.records.confirm-mass-deletions', {total: totalElements.value}),
      confirmation: t('referential.records.i-known-what-i-m-doing')
    },
    () => {
      openRecord.value = false;
      referentialApi.$dataset.massDeletions(props.dataset.code, filter.value)
        .then(count => {
          toast.warning(`${count} records deleted`)
          datasetRecordSearchKey.value += 1
        })
    }
  )
}

function exportDatasetRecords() {
  loading.value = true
  loadingText.value = t('referential.records.loading-export', {code: props.dataset.code})
  referentialApi.$dataset.exportRecords(props.dataset.code)
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `dataset-${props.dataset.code}-records.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .finally(() => clearLoading())
}

function importDatasetRecords(importData) {
  openImportRecords.value = false
  loading.value = true
  loadingText.value = t('referential.records.loading-import', {code: props.dataset.code})
  referentialApi.$dataset.importRecords(props.dataset.code, importData.file, importData.mode)
    .then(response => {
      toast.success(t('referential.records.import.success-msg'))
      datasetRecordSearchKey.value += 1
    })
    .finally(() => clearLoading())
}

function clearLoading() {
  loading.value = false;
  loadingText.value = null;
}
</script>