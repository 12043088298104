import {acceptHMRUpdate, defineStore} from "pinia";
import {ref} from "vue";

export const useSimulationStore = defineStore('simulation', () => {

    const selectedSimulationCase = ref(null)
    const currentDefinitions = ref([])

    const setSelectedSimulationCase = (simulationCase) => {
        if (simulationCase && simulationCase.inputs) {
            let references = new Set()
            simulationCase.inputs.forEach(input => {
                let split = input.reference.split("/");
                let tmp = null
                split.forEach(code => {
                    if (tmp !== null) {
                        tmp += '/' + code
                    } else {
                        tmp = code
                    }
                    references.add(tmp)
                })
            })
            let reduce = Array.from(references)
                .map(reference => {
                    if (reference.endsWith("]")) {
                        return reference.substring(0, reference.lastIndexOf('['))
                    }
                    return reference
                })
                .reduce((a, b) => (a[b] = (a[b] || 0) + 1, a), {});
            simulationCase.counts = new Map(Object.entries(reduce))
        }
        selectedSimulationCase.value = simulationCase
    }

    const setCurrentDefinitions = (definitions) => {
        currentDefinitions.value = definitions.filter(variable => variable.nature === 'INGRESS')
    }

    const getRootDefinitions = () => {
        return currentDefinitions.value.filter(variable => variable.reference.parent.indexOf("/") === -1)
    }

    const getChildrenDefinitions = (parentReference) => {
        return currentDefinitions.value.filter(variable => variable.reference.parent === parentReference)
    }

    const getNbInstanceVariable = (variableReference) => {
        return selectedSimulationCase.value.counts.get(variableReference)
    }

    return {
        selectedSimulationCase,
        currentDefinitions,
        setSelectedSimulationCase,
        setCurrentDefinitions,
        getRootDefinitions,
        getChildrenDefinitions,
        getNbInstanceVariable
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSimulationStore, import.meta.hot))
}