import BaseApi from "@/services/api/BaseApi";

export default class SupportApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    getState(module) {
        return this.securedFetch(`/${module}-api/state`)
            .then(response => this.handleJson(response));
    }

    getSwaggerSpec(module) {
        return this.securedFetch(`/${module}-api/v3/api-docs`)
            .then(response => this.handleJson(response));
    }

    getAutocompletion(collectionCode) {
        return this.securedFetch(`/catalog-api/v1/autocompletion/${collectionCode}`)
            .then(response => this.handleJson(response))
    }
}