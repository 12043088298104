<template>
  <v-card width="720px" min-height="420px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="$t('catalog.variable.duplicate.title', {label: toDuplicate.label})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="targetCode" :edit="true" class="mb-6" :label="$t('catalog.variable.duplicate.new-code')"/>
      <v-text-field v-model="targetLabel" :label="$t('catalog.variable.duplicate.new-label')" variant="outlined"
                    bg-color="white"/>
      <v-select v-model="targetCollection" :items="collections" :item-title="collectionItemTitle"
                :label="$t('catalog.variable.duplicate.target-collection')" variant="outlined" bg-color="white"
                return-object></v-select>
      <v-select v-model="targetParent" :items="composites" :label="$t('catalog.variable.duplicate.target-parent')"
                variant="outlined" bg-color="white"></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="duplicate">{{ $t('common.duplicate') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import {onMounted, ref, watch} from "vue";
import {useCatalogApi} from "@/composables/useCatalogApi";

const props = defineProps({
  toDuplicate: Object
})

let collections = ref([])
let composites = ref([])
let targetCode = ref(null)
let targetLabel = ref(null)
let targetCollection = ref(null)
let targetParent = ref(null)

let catalogApi = useCatalogApi()

onMounted(() => {
  catalogApi.$collection.list()
    .then(result => collections.value = result)
})

watch(targetCollection, (collection) => {
  targetParent.value = null
  composites.value = []
  catalogApi.$variable.getCompositesVariable(collection.code)
    .then(result => {
      composites.value.push(collection.code)
      result.forEach(e => composites.value.push(e))
    })
})

const emit = defineEmits(['canceled', 'duplicated'])

function cancel() {
  emit("canceled");
}

function duplicate() {
  const variable = {
    reference: {
      parent: targetParent.value,
      code: targetCode.value
    },
    label: targetLabel.value,
    type: props.toDuplicate.type
  }
  emit("duplicated", variable)
}

function collectionItemTitle(collection) {
  return `${collection.code} - ${collection.label}`
}

</script>