import BaseApi from "@/services/api/BaseApi";

export default class DatasetApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    list() {
        return this.call("/referential-api/v1/datasets", {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.list'
            }
        })
    }

    find(datasetCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}`, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.find',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    update(dataset) {
        return this.call(`/referential-api/v1/datasets/${dataset.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataset),
            action: {
                key: 'referential.dataset.actions.update',
                params: {
                    code: dataset.code
                }
            }
        })
    }

    delete(datasetCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.dataset.actions.delete',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    create(dataset) {
        return this.call("/referential-api/v1/datasets", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataset),
            action: {
                key: 'referential.dataset.actions.create'
            }
        })
    }

    addProperty(datasetCode, property) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/properties`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.dataset.actions.add-property',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    updateProperty(datasetCode, property) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/properties/${property.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.dataset.actions.update-property',
                params: {
                    code: datasetCode,
                    propertyCode: property.code
                }
            }
        })
    }

    deleteProperty(datasetCode, propertyCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/properties/${propertyCode}`, {
            responseFormat: 'json',
            method: 'DELETE',
            action: {
                key: 'referential.dataset.actions.delete-property',
                params: {
                    code: datasetCode,
                    propertyCode: propertyCode
                }
            }
        })
    }

    saveNewPropertiesOrder(datasetCode, newOrder) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/properties-order`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(newOrder)),
            action: {
                key: 'referential.dataset.actions.save-properties-order',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    searchRecords(datasetCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/datasets/${datasetCode}/records`
        if (query) {
            url += "?" + query
        }
        return this.call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.search-records',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    propertiesValues(datasetCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/datasets/${datasetCode}/properties-values`
        if (query) {
            url += "?" + query
        }
        return this.call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.search-values',
                params: {
                    code: datasetCode
                }
            }
        })
    }

    propertyValues(datasetCode, propertyCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/properties/${propertyCode}/values`, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.search-property-values',
                params: {
                    code: datasetCode,
                    propertyCode: propertyCode
                }
            }
        })
    }

    addRecord(datasetCode, record) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/records`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.dataset.actions.add-record',
                params: {
                    code: datasetCode,
                }
            }
        })
    }

    updateRecord(datasetCode, record) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/records/${record.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.dataset.actions.update-record',
                params: {
                    code: datasetCode,
                    recordCode: record.code,
                }
            }
        })
    }

    deleteRecord(datasetCode, recordCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/records/${recordCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.dataset.actions.delete-record',
                params: {
                    code: datasetCode,
                    recordCode: recordCode
                }
            }
        })
    }

    exportRecords(datasetCode) {
        return this.call(`/referential-api/v1/datasets/${datasetCode}/records/export`, {
            responseFormat: 'blob',
            method: 'POST',
            action: {
                key: 'referential.dataset.actions.export-records',
                params: {
                    code: datasetCode,
                }
            }
        })
    }

    importRecords(datasetCode, file, mode) {
        let formData = new FormData();
        formData.append( 'file', file );
        return this.call(`/referential-api/v1/datasets/${datasetCode}/records/import?mode=${mode}`, {
            responseFormat: 'ignore',
            method: 'POST',
            headers: {
                'Accept': ['multipart/form-data', 'application/json'],
            },
            body: formData,
            action: {
                key: 'referential.dataset.actions.import-records',
                params: {
                    code: datasetCode,
                }
            }
        })
    }

    massDeletions(datasetCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/datasets/${datasetCode}/records/mass-deletions`
        if (query) {
            url += "?" + query
        }
        return this.call(url, {
            responseFormat: 'json',
            method: 'POST',
            action: {
                key: 'referential.dataset.actions.records-mass-deletion',
                params: {
                    code: datasetCode,
                }
            }
        })
    }
}