<template>
  <ModuleHeader code="studio" />
  <v-row class="mt-4" justify="center">
    <v-col>
      <HomeCard code="catalog" :desc="$t('menu.catalog-desc')" />
    </v-col>
    <v-col>
      <HomeCard code="referential" :desc="$t('menu.referential-desc')" />
    </v-col>
  </v-row>
  <v-divider class="mt-8"></v-divider>
  <v-row class="mt-4" justify="center">
    <v-col cols="6">
      <HomeCard code="runner" :desc="$t('menu.runner-desc')" />
    </v-col>
<!--    <v-col>-->
<!--      <HomeCard code="batch" :desc="$t('menu.batch-desc')" />-->
<!--    </v-col>-->
  </v-row>
  <v-divider class="mt-8"></v-divider>
</template>
<script>
import HomeCard from "@/components/common/HomeCard.vue";
import ModuleHeader from "@/components/common/ModuleHeader.vue";

export default {
  name: 'Home',
  components: {ModuleHeader, HomeCard}
}
</script>