import {createRouter, createWebHistory} from 'vue-router'
import {toast} from 'vue3-toastify';
import Home from '@/views/Home.vue'
import Catalog from "@/views/catalog/Catalog.vue";
import Collection from '@/views/catalog/Collection.vue'
import Variable from "@/views/catalog/Variable.vue";
import Simulation from "@/views/catalog/Simulation.vue";
import Referential from "@/views/referential/Referential.vue";
import Dataset from "@/views/referential/Dataset.vue";
import Classifier from "@/views/referential/Classifier.vue";
import Batch from "@/views/batch/Batch.vue";
import Runner from "@/views/runner/Runner.vue";
import RunnableCollection from "@/views/runner/RunnableCollection.vue";
import Api from "@/views/Api.vue";
import About from "@/views/About.vue";
import Settings from "@/views/Settings.vue";
import Login from "@/views/auth/Login.vue";
import i18n from "@/plugins/i18n"
import {useAuthStore} from '@/store/auth'
import Callback from "@/views/auth/Callback.vue";
import SilentRefresh from "@/views/auth/SilentRefresh.vue";
import Logout from "@/views/auth/Logout.vue";
import {useAuth} from "@/composables/useAuth";
import {useApi} from "@/composables/useApi";

const routes = [
    {
        name: "home",
        path: '/',
        component: Home,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "catalog",
        path: '/catalog',
        component: Catalog,
        meta: {
            module: 'catalog'
        }
    },
    {
        name: "collection",
        path: '/catalog/collection/:collectionCode',
        component: Collection,
        meta: {
            module: 'catalog',
        }
    },
    {
        name: "variable",
        path: '/catalog/variable/:variableReference',
        component: Variable,
        meta: {
            module: 'catalog'
        }
    },
    {
        name: "simulation",
        path: '/catalog/simulation/:collectionCode',
        component: Simulation,
        meta: {
            module: 'catalog'
        }
    },
    {
        name: "referential",
        path: '/referential',
        component: Referential,
        meta: {
            module: 'referential'
        }
    },
    {
        name: "dataset",
        path: '/referential/dataset/:datasetCode',
        component: Dataset,
        meta: {
            module: 'referential'
        }
    },
    {
        name: "classifier",
        path: '/referential/classifier/:classifierCode',
        component: Classifier,
        meta: {
            module: 'referential'
        }
    },
    {
        name: "batch",
        path: '/batch',
        component: Batch,
        meta: {
            module: 'batch'
        }
    },
    {
        name: "runner",
        path: '/runner',
        component: Runner,
        meta: {
            module: 'runner'
        }
    },
    {
        name: "runnableCollection",
        path: '/runner/collection/:collectionCode',
        component: RunnableCollection,
        meta: {
            module: 'runner'
        }
    },
    {
        name: "api",
        path: '/api',
        component: Api,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "about",
        path: '/about',
        component: About,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "settings",
        path: '/settings',
        component: Settings,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "auth-callback",
        path: "/auth-callback",
        component: Callback,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "auth-silent-refresh",
        path: "/auth-silent-refresh",
        component: SilentRefresh,
        meta: {
            module: 'studio'
        }
    },
    {
        name: "logout",
        path: "/logout",
        component: Logout,
        meta: {
            module: 'studio'
        }
    }
];

let router = createRouter({
    history: createWebHistory(),
    routes: routes
});

const authFlowRoutes = ['/auth-callback', '/auth-silent-refresh', '/logout', '/login']

router.beforeEach(async (to, from) => {
    if (authFlowRoutes.includes(to.path)) {
        return true
    }
    const auth = useAuth()
    const user = await auth.$auth.getUser()
    if ((!user || user.expired) && !authFlowRoutes.includes(to.path)) {
        await router.push('/logout')
        return false
        // auth.$auth.signInRedirect()
    } else {
        const authStore = useAuthStore()
        authStore.setUser(user)
        const moduleName = to.meta.module
        if (moduleName && moduleName !== 'studio') {
            try {
                const api = useApi()
                await api.$support.getState(moduleName)
                return true
            } catch (eState) {
                const {t} = i18n.global
                toast.error(t("common.module-unavailable", {module: moduleName[0].toUpperCase() + moduleName.slice(1)}))
                return false
            }
        }
    }
})

export default router;

/*export default {
    install(app, options) {
        router.install(app)
        router.beforeEach(async (to, from) => {
            if (to.name === 'login') {
                return true
            }
            try {
                let result = await authGuard(to);
                const moduleName = to.meta.module
                if (result && moduleName !== 'studio') {
                    try {
                        let state = await SupportAPI.getState(moduleName)
                        return true
                    } catch (eState) {
                        const {t} = i18n.global
                        app.$toast.error(t("common.module-unavailable", {module: moduleName[0].toUpperCase() + moduleName.slice(1)}))
                        router.push(from)
                        return false
                    }
                }
                return result
            } catch (e) {
                console.log("error guard", e)
            }
        })
    }
}*/