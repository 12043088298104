<template>
  <div class="text-center">
    <v-menu transition="slide-y-transition">
      <template v-slot:activator="{ props: menu }">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: tooltip }">
            <v-btn v-bind="mergeProps(menu, tooltip)" icon="mdi-history" :color="edit ? '' : 'action'" :disabled="edit" />
          </template>
          <span>Show history</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title>
            <span class="font-weight-light" v-html="item" />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mergeProps } from 'vue'
import moment from "moment";
export default {
  name: 'ComponentHistory',
  props: {
    component: Object,
    edit: Boolean
  },
  data: () => ({
    tooltip: false,
  }),
  computed: {
    items() {
      if (this.component.modifiedByUser && this.component.createdDate !== this.component.lastModifiedDate) {
        return [
          'Created by <b>' + this.component.createdByUser + '</b> on ' + this.formatDate(this.component.createdDate),
          'Edited by <b>' + this.component.modifiedByUser + '</b> on ' + this.formatDate(this.component.lastModifiedDate)
        ]
      }
      return ['Created by <b>' + this.component.createdByUser + '</b> on ' + this.component.createdDate]
    }
  },
  methods: {
    formatDate(date) {
      return moment(String(date)).calendar()
    },
    mergeProps
  }
}
</script>