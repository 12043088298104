<template>
  <h1>About RuleShake</h1>

  <v-card flat>
    <v-card-text>
      <p>
        For more information, please visit <a href="https://ruleshake.com">https://ruleshake.com</a>
      </p>
    </v-card-text>
  </v-card>

  <v-card class="mt-4">
    <v-card-title>
      Credits
    </v-card-title>
    <v-card-text>
      Icons are provided by :
        <ul class="mt-4 ml-6">
            <li><a href="https://www.flaticon.com/fr/icones-gratuites/helice" title="helice icônes">Helice icônes créées par
                Freepik - Flaticon</a></li>
            <li><a href="https://www.flaticon.com/fr/icones-gratuites/multi" title="multi icônes">Multi icônes créées par
                Freepik - Flaticon</a></li>
            <li><a href="https://www.flaticon.com/fr/icones-gratuites/categorie" title="categorie icônes">Categorie icônes
                créées par Freepik - Flaticon</a></li>
            <li><a href="https://www.flaticon.com/fr/icones-gratuites/turbine" title="turbine icônes">Turbine icônes créées
                par Freepik - Flaticon</a></li>
            <li> <a href="https://www.flaticon.com/fr/icones-gratuites/outils" title="outils icônes">Outils icônes créées par
                Freepik - Flaticon</a></li>
            <li><a href="https://www.flaticon.com/fr/icones-gratuites/cycle" title="cycle icônes">Cycle icônes créées par
                Freepik - Flaticon</a></li>
            <li><a href="https://www.flaticon.com/fr/icones-gratuites/planificateur" title="planificateur icônes">Planificateur
                icônes créées par Freepik - Flaticon</a></li>
        </ul>



    </v-card-text>
  </v-card>
</template>
<script setup>
</script>