<template>
  <v-menu rounded>
    <template v-slot:activator="{ props }">
      <v-btn size="x-large" v-bind="props" append-icon="mdi-chevron-down">
        <div class="d-flex flex-column justify-center">
          <v-row>
            <span class="text-body-1 font-weight-medium">{{ user.name }}</span>
          </v-row>
          <v-row>
            <v-chip color="yellow" size="x-small">org: {{ user['ruleshake/organization'] }}</v-chip>
          </v-row>
        </div>
      </v-btn>
    </template>
    <v-card min-width="200px">
      <v-card-text>
        <div class="mx-auto text-center">
          <p class="text-h5 my-1">{{ user.name }}</p>
          <v-chip label color="primary" density="comfortable">
            @{{ user.nickname }}
          </v-chip>
          <v-divider class="my-3"></v-divider>
          <v-row class="pa-0">
            <v-col class="pa-1">
              <ButtonWithTooltip icon="mdi-cog" :tooltip-text="t('common.settings')"
                                 @clicked="router.push('/settings')"/>
            </v-col>
            <v-divider vertical/>
            <v-col class="pa-1">
              <ButtonWithTooltip icon="mdi-logout" :tooltip-text="t('common.logout')" @clicked="doLogout"/>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script setup>
import {useI18n} from 'vue-i18n'
import {useRouter} from "vue-router";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue"
import {ref} from "vue";
import {useAuth} from "@/composables/useAuth";

const {t} = useI18n()
const router = useRouter();

let user = ref({})
let auth = useAuth();
auth.$auth.getUser()
  .then(u => user.value = u.profile)

function doLogout() {
  router.push('/logout')
}
</script>